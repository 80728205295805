// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Material UI's
import { styled } from '@mui/material/styles';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));

export default RootStyle;
