/**
 * @description - The main file for the execution
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import ReactDOM from 'react-dom';
// Routing
import { BrowserRouter } from 'react-router-dom';
// Redux - Global state
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// StrictMode is a tool for highlighting potential problems in an application
import { StrictMode } from 'react';
// If we are doing anything asynchronous on the server,
// we need Helmet to encapsulate data on a per-request basis,
// this package does just that.
import { HelmetProvider } from 'react-helmet-async';
// Material UI's
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// Server - Firebase
// Initialization of the firebase
import { initializeApp } from 'firebase/app';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Components
import { LoadingScreen } from './components';
// For reporting - ToDo Add Web Vitals reporting logic
import reportWebVitals from './reportWebVitals';
// Contexts
import {
  SettingsProvider,
  CollapseDrawerProvider,
  FirebaseAuthProvider
} from './contexts';
// Store/Redux
import { store, persistor } from './redux/store';
// Firebase configs
import { firebaseConfig } from './configs';

// Applications
import { BisflowApp, AdminApp, ContractsApp } from './apps';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

// Creating Firebase Instance
initializeApp(firebaseConfig);

/**
 * @description - The method is getting the application from environment.
 */
const getApplication = () => {
  // Getting current app from environment variable
  const appName = process.env.REACT_APP_NAME;
  switch (appName) {
    case 'ADMIN':
      return <AdminApp />;
    case 'CONTRACTS':
      return <ContractsApp />;
    case 'BISFLOW':
      return <BisflowApp />;
    default:
      return <BisflowApp />;
  }
};

/**
 * @description - Main dom - Rendering ReactNode
 */
ReactDOM.render(
  // To highlight potential problems in an application
  <StrictMode>
    {/* Server and Client now requires <HelmetProvider> to encapsulate state
    per request */}
    <HelmetProvider>
      {/* Redux - Global state */}
      <ReduxProvider store={store}>
        {/* Persist component to safe state on local storage */}
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          {/* Translation/Localization provider */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* Settings Provider */}
            <SettingsProvider>
              {/* Collapse Drawer provider - ToDo analyse */}
              <CollapseDrawerProvider>
                {/* Router */}
                <BrowserRouter>
                  {/* Authentication Provider */}
                  <FirebaseAuthProvider>
                    <ContractsApp />
                  </FirebaseAuthProvider>
                </BrowserRouter>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
