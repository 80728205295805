/**
 * @description - ToDo add detailed description
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Material UI's icons
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import DeviceHubTwoToneIcon from '@mui/icons-material/DeviceHubTwoTone';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Style
import SvgIconStyle from './SvgIconStyle';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The method is getting svg icon from static directory and
 * wrapping into SvgIconStyle to return that object with the selected icon
 * @param name - icon name in the static directory
 */
const getSvgIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

/**
 * @description - The method is generating icon for side bar
 * @param Component
 */
const getIcon = (Component: any) => (
  <Component sx={{ width: 22, height: 22 }} />
);

export const team = getIcon(PeopleAltTwoToneIcon);
export const process = getIcon(DeviceHubTwoToneIcon);
export const forms = getIcon(LibraryBooksTwoToneIcon);
export const groups = getIcon(RecentActorsTwoToneIcon);
export const departments = getIcon(DomainTwoToneIcon);
export const payments = getSvgIcon('ic_payment');
export const authorizations = getSvgIcon('ic_authorization');
export const documents = getSvgIcon('ic_documents');
export const archive = getSvgIcon('ic_archive');
export const requests = getSvgIcon('ic_request');
export const invoices = getSvgIcon('ic_invoice');
export const map = getSvgIcon('ic_map');
export const box = getSvgIcon('ic_box');
export const budgets = getSvgIcon('ic_budgets');
export const stock = getSvgIcon('ic_stock');
export const blog = getSvgIcon('ic_blog');
export const cart = getSvgIcon('ic_cart');
export const chat = getSvgIcon('ic_chat');
export const mail = getSvgIcon('ic_mail');
export const page = getSvgIcon('ic_page');
export const user = getSvgIcon('ic_user');
export const copy = getSvgIcon('ic_copy');
export const error = getSvgIcon('ic_error');
export const charts = getSvgIcon('ic_charts');
export const editor = getSvgIcon('ic_editor');
export const upload = getSvgIcon('ic_upload');
export const animate = getSvgIcon('ic_animate');
export const calendar = getSvgIcon('ic_calendar');
export const elements = getSvgIcon('ic_elements');
export const carousel = getSvgIcon('ic_carousel');
export const language = getSvgIcon('ic_language');
export const ecommerce = getSvgIcon('ic_ecommerce');
export const analytics = getSvgIcon('ic_analytics');
export const dashboard = getSvgIcon('ic_dashboard');
export const components = getSvgIcon('ic_components');
export const authenticator = getSvgIcon('ic_authenticator');
export const reports1 = getSvgIcon('ic_report');
export const reports2 = getSvgIcon('ic_report_doc');
export const store = getSvgIcon('ic_store');
