import { useState } from 'react';
import { Icon } from '@iconify/react';
// material
import { alpha } from '@mui/material/styles';
import { Button } from '@mui/material';

// ----------------------------------------------------------------------

export default function SettingFullscreen() {
  const [fullscreen, setFullscreen] = useState(false);

  const toggleFullScreen = () => {
    try {
      if (!document?.fullscreenElement) {
        document.documentElement
          .requestFullscreen()
          .then(() => setFullscreen(true))
          .catch((err) => {
            alert(
              `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
            );
          });
      } else if (document?.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => setFullscreen(false))
          .catch((err) => {
            alert(
              `Error attempting to disable full-screen mode: ${err.message} (${err.name})`
            );
          });
      }
    } catch (e) {
      alert("Can't make full screen on this browser");
    }
  };

  return (
    <Button
      fullWidth
      size="large"
      variant="outlined"
      color={fullscreen ? 'primary' : 'inherit'}
      startIcon={
        <Icon icon={fullscreen ? 'mdi:fullscreen-exit' : 'mdi:fullscreen'} />
      }
      onClick={toggleFullScreen}
      sx={{
        fontSize: 14,
        ...(fullscreen && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.selectedOpacity
            )
        })
      }}
    >
      {fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
    </Button>
  );
}
