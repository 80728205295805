/**
 * @description - React hook method for Collapse Context provider provider
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

import { useContext } from 'react';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

import { CollapseDrawerContext } from 'contexts';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The hook that provides Collapse Drawer Context
 */
const useCollapseDrawer = () => useContext(CollapseDrawerContext);

export default useCollapseDrawer;
