/**
 * @description - The Contract Sign Page's styles
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Material UI's
import { styled } from '@mui/material/styles';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

export const ContractSignPageToolbarRootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(5)
}));
