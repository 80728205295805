/**
 * @description - The main file for the execution
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { useMemo, ReactNode } from 'react';
// Material UI's
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Hooks
import { useSettings } from '../hooks';
// Theme
import {
  typography,
  shape,
  palette,
  shadows,
  customShadows,
  breakpoints,
  GlobalStyles,
  componentsOverride
} from 'theme';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Interface for the theme configuration
 */
type ThemeConfigProps = {
  children: ReactNode;
};

/**
 * @description - The bisflow theme configuration provider
 * @param children
 */
export default function BisflowThemeConfig({ children }: ThemeConfigProps) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight
        ? { ...palette.light, mode: 'light' }
        : { ...palette.dark, mode: 'dark' },
      shape,
      typography,
      breakpoints,
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
