/**
 * @description - Bisflow application, ToDo add description
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { useRoutes } from 'react-router-dom';

// Theme
import { BisflowThemeConfig, GlobalStyles } from 'theme';
// Components
import {
  ThemePrimaryColor,
  NotistackProvider,
  LoadingScreen,
  ProgressBarStyle,
  BaseOptionChartStyle,
  Settings,
  ScrollToTop,
  GoogleAnalytics
} from 'components';
// Hooks
import { useAuth } from 'hooks';
// Types
import { RouteItem } from '@types';
// Routes
import { authRoutes, bisflowRoutes } from 'routes';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Application based related routes.
 */
const routes: RouteItem[] = [bisflowRoutes, authRoutes];

/**
 * @description - The router component in order to avoid
 * react-hooks/rules-of-hooks react error during useRoutes call
 * @constructor
 */
function Router() {
  return useRoutes(routes);
}

/**
 * @description - Bisflow application's view
 * @constructor
 */
export default function BisflowApp() {
  // Authentication initialization status
  const { isInitialized } = useAuth();
  // The view
  return (
    // Theme palette configuration for bisflow app
    <BisflowThemeConfig>
      {/* Primary color provider */}
      <ThemePrimaryColor>
        <NotistackProvider>
          <GlobalStyles />
          <ProgressBarStyle />
          <BaseOptionChartStyle />
          <Settings />
          <ScrollToTop />
          <GoogleAnalytics />
          {isInitialized ? <Router /> : <LoadingScreen />}
        </NotistackProvider>
      </ThemePrimaryColor>
    </BisflowThemeConfig>
  );
}
