/**
 * @description - The utility to create an avatar from the user profile. If the
 * image does not exist, the avatar is displaying user's first name
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

import { capitalize } from 'lodash';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

// All letters are sorted by colors
const PRIMARY_NAME = ['A', 'N', 'H', 'L', 'Q', '9', '8'];
const INFO_NAME = ['F', 'G', 'T', 'I', 'J', '1', '2', '3'];
const SUCCESS_NAME = ['K', 'D', 'Y', 'B', 'O', '4', '5'];
const WARNING_NAME = ['P', 'E', 'R', 'S', 'C', 'U', '6', '7'];
const ERROR_NAME = ['V', 'W', 'X', 'M', 'Z'];

/**
 * @description - The method is getting first character from the name
 * @param name
 */
function getFirstCharacter(name: string) {
  return capitalize(name && name.charAt(0));
}

/**
 * @description - The method is getting the color based on the name
 * @param name
 */
function getAvatarColor(name: string) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return 'primary';
  if (INFO_NAME.includes(getFirstCharacter(name))) return 'info';
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return 'success';
  if (WARNING_NAME.includes(getFirstCharacter(name))) return 'warning';
  if (ERROR_NAME.includes(getFirstCharacter(name))) return 'error';
  return 'default';
}

/**
 * @description - The method is generating avatar name and color based on it's
 * name
 * @param name
 */
export default function createAvatar(name: string) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name)
  } as const;
}
