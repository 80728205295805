/**
 * @description - Style component for Side bar component.
 * ToDo add detailed description
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { ReactNode } from 'react';
// Material UI's style
import { styled } from '@mui/material/styles';
// Material UI's
import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListSubheader
} from '@mui/material';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

export const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

export const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

/**
 * @description - The interface for the side bar content's list item style
 */
interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

/**
 * @description - Nav bar content's list item style
 */
export const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

/**
 * @description - The nav bar content's list item's icon style
 */
export const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

/**
 * @description - The sidebar item's header style
 */
export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary
}));
