/**
 * @description - The login-password-password page view
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Material UI's
import { Box, Stack, Link, Container, Typography } from '@mui/material';
// React
import { Link as RouterLink } from 'react-router-dom';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Styles
import { RootStyle, SectionStyle, ContentStyle } from './loginPageStyles';
// Paths
import { PATH_AUTH } from 'routes';
// Components
import { MHidden } from 'components';
import { AuthLayout } from 'layouts';
// Forms
import { LoginEmailLinkForm } from 'forms';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The login-password-password page view
 * @constructor
 */
export default function Login() {
  return (
    <RootStyle title="Login | Bisflow">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.register}
        >
          Get started
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to Bisflow.io
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Enter your email below and shortly you will receive a link
              </Typography>
            </Box>
          </Stack>
          <LoginEmailLinkForm />
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
