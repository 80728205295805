/**
 * @description - Firebase Application Config https://firebase.google.com/
 * ToDo change config to be applciation name environment variable dependant
 */
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

/**
 * @description - Google pam key
 */
export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

/**
 * @description - Google Analytics Configuration
 */
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
