/**
 * @description - The bisflow application selection page view
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Material UI's components
import { Grid, Container } from '@mui/material';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Local components
import {
  Page,
  CardApplicationButton,
  // Icons
  LogoBisflowGreen,
  LogoBisflowRed,
  LogoBisflowBlue,
  LogoBisflow
} from 'components';
import {
  PATH_ADMIN_FULL,
  PATH_CLM_FULL,
  PATH_PROCUREMENT_FULL,
  PATH_REQUEST_MANAGEMENT_FULL,
  PATH_HR_FULL,
  PATH_PAYVA_FULL
} from 'routes';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The login-password-password page view
 * @constructor
 */
export default function BisflowAppsView() {
  // Icons
  return (
    <Page title="Bisflow: Application Selection">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <CardApplicationButton
              path={PATH_REQUEST_MANAGEMENT_FULL}
              title={'Requests'}
              subtitle={'Requests and requests management'}
              icon={<LogoBisflowRed sx={{ width: 128, height: 128 }} />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardApplicationButton
              path={PATH_PROCUREMENT_FULL}
              title={'Procurement'}
              subtitle={'Procurement management application'}
              icon={<LogoBisflowBlue sx={{ width: 128, height: 128 }} />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardApplicationButton
              path={PATH_CLM_FULL}
              title={'Contract'}
              subtitle={'Contract Lifecycle management application'}
              icon={<LogoBisflowGreen sx={{ width: 128, height: 128 }} />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardApplicationButton
              path={PATH_PAYVA_FULL}
              title={'Accountant'}
              subtitle={'Expense management application'}
              icon={
                <LogoBisflow
                  lighter={'#282828'}
                  light={'#282828'}
                  primary={'#282828'}
                  dark={'#282828'}
                  darker={'#282828'}
                  sx={{ width: 128, height: 128 }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardApplicationButton
              path={PATH_HR_FULL}
              title={'HR'}
              subtitle={'Contract Lifecycle management application'}
              icon={
                <LogoBisflow
                  lighter={'#ff9600'}
                  light={'#ff9600'}
                  primary={'#ff9600'}
                  dark={'#ff9600'}
                  darker={'#ff9600'}
                  sx={{ width: 128, height: 128 }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardApplicationButton
              path={PATH_ADMIN_FULL}
              title={'Administrative'}
              subtitle={'Admin account management application'}
              icon={
                <LogoBisflow
                  lighter={'#00FFFF'}
                  light={'#00FFFF'}
                  primary={'#00FFFF'}
                  dark={'#00FFFF'}
                  darker={'#00FFFF'}
                  sx={{ width: 128, height: 128 }}
                />
              }
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
