/**
 * @description - Style component for Maintenance page.
 * ToDo add detailed description
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Material UI's style
import { styled } from '@mui/material/styles';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Helmet referenced wrapper for page's title change and Google's analytic
import Page from '../../components/page';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

export const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

export const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));
