/**
 * @description - Style objects for the pdf viewer component
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

import { Font, StyleSheet } from '@react-pdf/renderer';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The available fonts for the pdf
 */
Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf' },
    { src: '/fonts/Roboto-Bold.ttf' }
  ]
});
Font.register({
  family: 'Noto Sans Armenian',
  fonts: [
    { src: '/fonts/NotoSansArmenian-Regular.ttf' },
    { src: '/fonts/NotoSansArmenian-Bold.ttf' }
  ]
});
Font.register({
  family: 'Noto Serif Armenian',
  fonts: [
    { src: '/fonts/NotoSerifArmenian-Regular.ttf' },
    { src: '/fonts/NotoSerifArmenian-Bold.ttf' }
  ]
});

/**
 * @description - The style sheet for the pdf viewer component that is using
 * react pdf renderer module.
 */
export default StyleSheet.create({
  col4: { width: '25%' },
  col8: { width: '75%' },
  col6: { width: '50%', paddingLeft: '10px' },
  col12: { width: '100%' },
  col12Arm: { width: '100%', fontFamily: 'Noto Serif Armenian' },
  col6Arm: {
    width: '50%',
    fontFamily: 'Noto Serif Armenian',
    paddingRight: '10px'
  },
  mb8: { marginBottom: 8 },
  mb40: { marginBottom: 40 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: 700,
    letterSpacing: 1.2,
    textTransform: 'uppercase'
  },
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 13, fontWeight: 700 },
  body1: { fontSize: 10, textAlign: 'justify', paddingTop: '5px' },
  subtitle2: { fontSize: 10, fontWeight: 700 },
  alignRight: { textAlign: 'right' },
  page: {
    padding: '40px 24px 0 24px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#fff'
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    padding: 24,
    margin: 'auto',
    borderTopWidth: 1,
    borderStyle: 'solid',
    position: 'absolute',
    borderColor: '#DFE3E8'
  },
  gridContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  table: { display: 'flex', width: 'auto' },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    padding: '8px 0',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE3E8'
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: '5%' },
  tableCell_2: { width: '50%', paddingRight: 16 },
  tableCell_3: { width: '15%' }
});
