/**
 * @description - Guest guard component. Allowing to open the child page only
 * when user is not signed in. Otherwise it will redirect to the provided path.
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Hooks
import { useAuth } from 'hooks';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Guest guard component's interface.
 */
type GuestGuardProps = {
  children: ReactNode;
  path: string;
};

/**
 * @description - The Guest guard component view
 * @param children
 * @param pathToNavigate
 * @constructor
 */
export default function GuestGuard({ children, path }: GuestGuardProps) {
  // Authentications status
  const { isAuthenticated } = useAuth();
  // Checking if authenticated then redirect to the provided path, otherwise
  // display the child component
  if (isAuthenticated) {
    return <Navigate to={path} />;
  }

  // Returning child component
  return <>{children}</>;
}
