/**
 * @description - The routs and loadable components for the application
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import React, { Suspense } from 'react';
// Router react dom
import { useLocation } from 'react-router-dom';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

import { LoadingScreen } from '../components';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Page Loadable component.
 * @param Component
 * @constructor
 */
export function Loadable(Component: React.ElementType) {
  return function SourceComponent(props: any) {
    const { pathname } = useLocation();
    const isDashboard = pathname.includes('/dashboard');

    return (
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              ...(!isDashboard && {
                top: 0,
                left: 0,
                width: 1,
                zIndex: 9999,
                position: 'fixed'
              })
            }}
          />
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
}

/**
 * @description - Paths
 */
export * from './paths';

/**
 * @description - Routs
 */
export { default as authRoutes } from './auth-routes';
export { default as bisflowRoutes } from './bisflow-routes';
export { default as contractsRoutes } from './contracts-routes';
export { default as adminRoutes } from './admin-routes';
export { default as adminSidebarConfig } from './admin-sidebar-config';
