/**
 * @description - Login Page's form.
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Notistack
import { useSnackbar } from 'notistack';
// Yup for rendering forms
import * as Yup from 'yup';
// Formik
import { useFormik, Form, FormikProvider } from 'formik';
// Icons
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// Material UI's
import { Stack, Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Hooks
import { useAuth, useIsMountedRef } from 'hooks';
// Components
import { MIconButton } from 'components';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Interface for the initial values
 */
type InitialValues = {
  email: string;
  afterSubmit?: string;
};

/**
 * @description - Login Page's form view
 * @constructor
 */
export default function LoginEmailLinkForm() {
  const { signWithEmailLink } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await signWithEmailLink(values.email);
        enqueueSnackbar('Email sent successfully', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          if (error instanceof Error) {
            setErrors({ afterSubmit: error.message });
          }
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
