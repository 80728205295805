/**
 * @description - scroll bar component.
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// SimpleBar does NOT implement a custom scroll behaviour.
// It keeps the native overflow: auto scroll and only replace the scrollbar visual appearance.
import { Props } from 'simplebar-react';
// Material UI's core components
import { Box, BoxProps } from '@mui/material';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Scrollbar's style
import { SimpleBarStyle, RootStyle } from './simpleBarStyle';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The scroll bar component's view
 * @param children - children content's component
 * @param sx - external style, based on top level component can be changed
 * @param other - other props that can come from top level component
 * @constructor
 */
export default function Scrollbar({
  children,
  sx,
  ...other
}: BoxProps & Props) {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
}
