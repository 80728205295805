/**
 * @description - React hook method for detecting mounted parents
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { useRef, useEffect } from 'react';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The method is checking if the reference is mounted or not
 */
export default function useIsMountedRef() {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
}
