// ========================================================================== //
// ================================= COMMON ================================= //
// ========================================================================== //
export const USERS = 'users';

// All paths
const paths = {
  USERS
};
export default paths;
