/**
 * @description - The signature drawer view component. Allowing to draw a
 * signature
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { MutableRefObject, useRef } from 'react';
// Material UI's
import { Button } from '@mui/material';
// The signature canvas external library
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas-react17-compatible';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

interface SignatureDrawerProps {
  onDialogClick: (base64Image: any) => void;
}

export default function SignatureDrawer({
  onDialogClick
}: SignatureDrawerProps) {
  const getWidth = () => {
    return window.innerWidth;
  };

  const getHeight = () => {
    return window.innerHeight - 50;
  };

  let padRef = useRef() as MutableRefObject<any>;
  const clear = () => {
    if (padRef) {
      padRef.current.clear();
    }
  };
  const save = () => {
    const currentSignature = padRef.current
      .getTrimmedCanvas()
      .toDataURL('image/png');
    onDialogClick(currentSignature);
  };
  const onCancel = () => {
    onDialogClick(null);
  };
  return (
    <div style={{ width: getWidth(), height: getHeight() }}>
      <Button sx={{ margin: 2 }} onClick={save}>
        SIGN
      </Button>
      <Button sx={{ margin: 2 }} onClick={clear} color={'inherit'}>
        Clear
      </Button>
      <Button sx={{ margin: 2 }} onClick={onCancel} color={'error'}>
        Cancel
      </Button>
      <SignatureCanvas
        penColor="blue"
        canvasProps={{
          width: getWidth(),
          height: getHeight(),
          className: 'sigCanvas'
        }}
        ref={padRef}
      />
    </div>
  );
}
