/**
 * @description - The Auth Layout page view
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { ReactNode } from 'react';
// Material UI's
import { Typography } from '@mui/material';
// React
import { Link as RouterLink } from 'react-router-dom';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Styles
import { HeaderStyle } from './authLayoutStyles';
// Components
import { MHidden, LogoBisflowRed } from 'components';
// Paths
import { ROOTS_HOME } from '../../routes';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The auth layout property interface
 */
type AuthLayoutProps = {
  children: ReactNode;
};

/**
 * @description - The auth layout view
 * @param children
 * @constructor
 */
export default function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <HeaderStyle>
      <RouterLink to={ROOTS_HOME}>
        <LogoBisflowRed />
      </RouterLink>

      <MHidden width="smDown">
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 }
          }}
        >
          {children}
        </Typography>
      </MHidden>
    </HeaderStyle>
  );
}
