/**
 * @description - Maintenance page, contains simple info about page maintenance
 * status.
 * ToDo add detailed description
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Router links
import { Link as RouterLink } from 'react-router-dom';
// Material UI's core components
import { Button, Typography, Container, Box } from '@mui/material';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Logo
import { LogoBisflowGreen } from 'components';
// Local styles
import { RootStyle, HeaderStyle } from './maintenanceStyles';
// Paths
import { PATH_HOME } from 'routes';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

export default function Maintenance() {
  return (
    <RootStyle title="Maintenance | Bisflow">
      <HeaderStyle>
        <RouterLink to="/">
          <LogoBisflowGreen />
        </RouterLink>
      </HeaderStyle>

      <Container sx={{ textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Page currently under maintenance
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          We are currently working hard on this page!
        </Typography>

        <Box
          component="img"
          alt="comingsoon"
          src="/static/illustrations/illustration_maintenance.svg"
          sx={{ width: '100%', maxHeight: 240, my: { xs: 5, sm: 10 } }}
        />

        <Button
          variant="contained"
          size="large"
          component={RouterLink}
          to={PATH_HOME.root}
        >
          Go to Home
        </Button>
      </Container>
    </RootStyle>
  );
}
