/**
 * @description - Style component for Application button component.
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Material UI's
import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - root style of the application card button
 */
export const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3)
}));
