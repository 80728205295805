/**
 * @description - The theme primary color provider
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { ReactNode, useMemo } from 'react';
// Material UI's
import {
  alpha,
  ThemeProvider,
  createTheme,
  useTheme
} from '@mui/material/styles';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Hooks
import { useSettings } from 'hooks';
// Theme
import { componentsOverride } from 'theme';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

// Interface for the provider
type ThemePrimaryColorProps = {
  children: ReactNode;
};

/**
 * @description - HoC for the primary color provider
 * @param children
 * @constructor
 */
export default function Index({ children }: ThemePrimaryColorProps) {
  const defaultTheme = useTheme();
  const { setColor } = useSettings();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: setColor
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`
      }
    }),
    [setColor, defaultTheme]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
