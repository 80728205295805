/**
 * @description - The pdf viewer component that is using content parsing into
 * @react-pdf/renderer module component
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

import { Page, View, Text, Image, Document } from '@react-pdf/renderer';
import { useState, useEffect } from 'react';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Styles
import pdfViewerStyle from './pdfViewerStyles';
// Types
import { TypeDocumentContent } from '@types';
import { parseDocumentTitles, parseDocumentContent } from 'utils/parseDocument';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The interface for the pdf viewer component. Contains pdf
 * document content type
 */
interface PdfViewerProps {
  documentContent: TypeDocumentContent[] | null;
  documentExternalData: any;
}

/**
 * @description - The interface for the pdf viewer component. Contains pdf
 * document content type
 */
interface ActPdfViewerProps {
  actContent: any;
}

/**
 * @description - The view component for the pdf viewer
 * @param documentContent
 * @param documentExternalData
 * @constructor
 */
function PdfContentViewer({
  documentContent,
  documentExternalData
}: PdfViewerProps) {
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    if (documentExternalData && documentExternalData !== documentData) {
      setDocumentData(documentExternalData);
    }
  }, [documentExternalData]);
  /**
   * @description - The method is getting the document titles in a form of
   * Typography objects list
   * @param language,
   * @param data,
   */
  const getDocumentTitles = (
    language: 'am' | 'en' | 'ru',
    data: string[] | null
  ) => {
    const finalTitles = parseDocumentTitles(documentContent, language);
    if (finalTitles && finalTitles.length > 0 && data) {
      let listOfTitle: any = [];
      finalTitles.map((value, index) => {
        let finalValue = value.value;
        if (data) {
          const dataList = Object.values(data);
          value.value
            .split(' ')
            .filter((item) => item.includes('bisflow.value.'))
            .map((inputVariable) => {
              const valueIndex = parseInt(
                inputVariable.replace('bisflow.value.', '')
              );
              const inputValue = dataList[valueIndex];
              if (inputValue) {
                finalValue = finalValue.replace(inputVariable, inputValue);
              }
            });
        }
        listOfTitle.push(
          <Text key={`title_${language}_${index}`} style={pdfViewerStyle.h4}>
            {finalValue}
          </Text>
        );
      });
      return listOfTitle;
    }
    return <Text style={pdfViewerStyle.h4}>No Title</Text>;
  };

  /**
   * @description - The method is getting the document content in a form of
   * Typography objects list
   * @param language
   * * @param data,
   */
  const getDocumentContent = (language: 'am' | 'en' | 'ru', data: string[]) => {
    const finalContent = parseDocumentContent(documentContent, language);
    if (finalContent && finalContent.length > 0 && data) {
      return finalContent.map((value, index) => {
        let finalValue = value.value;
        const dataList = Object.values(data);
        value.value
          .split(' ')
          .filter((item) => item.includes('bisflow.value.'))
          .map((inputVariable) => {
            const valueIndex = parseInt(
              inputVariable.replace('bisflow.value.', '')
            );
            const inputValue = dataList[valueIndex];
            if (inputValue) {
              finalValue = finalValue.replace(inputVariable, inputValue);
            }
          });
        if (value.type === 'paragraph') {
          return (
            <Text key={`par_${language}_${index}`} style={pdfViewerStyle.body1}>
              {finalValue}
            </Text>
          );
        } else {
          return (
            <Text
              key={`par_${language}_${index}`}
              style={pdfViewerStyle.subtitle2}
            >
              {finalValue}
            </Text>
          );
        }
      });
    }
    return <Text style={pdfViewerStyle.body1}>No Content</Text>;
  };

  if (documentData) {
    return (
      <Document>
        <Page size="A4" style={pdfViewerStyle.page}>
          <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
            <View style={pdfViewerStyle.col6Arm}>
              {/* @ts-ignore */}
              {getDocumentTitles('am', documentData?.fields?.am)}
              <Text style={pdfViewerStyle.body1}>ք. Երևան</Text>
              <Text style={pdfViewerStyle.body1}>կնքման վայր</Text>
              {/* @ts-ignore */}
              {getDocumentContent('am', documentData?.fields?.am)}
            </View>
            <View style={pdfViewerStyle.col6}>
              {/* @ts-ignore */}
              {getDocumentTitles('en', documentData?.fields?.en)}
              {/*<Text style={pdfViewerStyle.body1}>Yerevan</Text>*/}
              {/*<Text style={pdfViewerStyle.body1}>Place of signing</Text>*/}
              {/* @ts-ignore */}
              {getDocumentContent('en', documentData?.fields?.en)}
            </View>
          </View>
        </Page>
        <Page size="A4" style={pdfViewerStyle.page}>
          <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
            <View style={pdfViewerStyle.col12Arm}>
              <Text style={pdfViewerStyle.h4}>ԿԱՏԱՐՈՂ</Text>
            </View>
            <View style={pdfViewerStyle.col6Arm}>
              <Text style={pdfViewerStyle.subtitle2}>
                ԴԻԼԻՋԱՆ ԻՆՏԵՐՆԵՅՇՆԼ ՍՔՈՒԼ ՕՖ ԱՐՄԵՆԻԱ Հիմնադրամ
              </Text>
              <Text style={pdfViewerStyle.subtitle2}>ՀՎՀՀ՝ 00108599</Text>
            </View>
            <View style={pdfViewerStyle.col6}>
              <Text style={pdfViewerStyle.subtitle2}>
                DILIJAN INTERNATIONAL SCHOOL OF ARMENIA Foundation
              </Text>
              <Text style={pdfViewerStyle.subtitle2}>TIN: 00108599</Text>
            </View>
          </View>
          {/* @ts-ignore */}
          {documentData?.signature_side1 && (
            <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
              <View style={pdfViewerStyle.col4}>
                <Image
                  // @ts-ignore
                  src={documentData.signature_side1}
                  style={{ height: 48 }}
                />
              </View>
            </View>
          )}
          <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
            <View style={pdfViewerStyle.col12Arm}>
              <Text style={pdfViewerStyle.h4}>ԿԱՏԱՐՈՂ</Text>
            </View>
            <View style={pdfViewerStyle.col12Arm}>
              {
                // @ts-ignore
                documentData?.requisites?.am?.map((item, index) => (
                  <Text
                    key={`requisite_${item}_${index}`}
                    style={pdfViewerStyle.subtitle2}
                  >
                    {item}
                  </Text>
                ))
              }
            </View>
          </View>
          {/* @ts-ignore */}
          {documentData?.signature_side2 && (
            <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
              <View style={pdfViewerStyle.col4}>
                <Image
                  // @ts-ignore
                  src={documentData.signature_side2}
                  style={{ height: 48 }}
                />
              </View>
            </View>
          )}
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4" style={pdfViewerStyle.page} />
    </Document>
  );
}

export function ActPdfContentViewer({ actContent }: ActPdfViewerProps) {
  const parseActRequisites = (language: string) => {
    const requisites = actContent?.requisites?.[language];
    if (requisites) {
      const requisiteContent: any = [];
      requisites.map((value: string, index: number) => {
        requisiteContent.push(
          <Text
            key={`requisite_${value}_${index}`}
            style={pdfViewerStyle.subtitle2}
          >
            {value}
          </Text>
        );
      });
      return requisiteContent;
    }
  };
  if (actContent) {
    return (
      <Document>
        <Page size="A4" style={pdfViewerStyle.page}>
          <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
            <View style={pdfViewerStyle.col6Arm}>
              <Text style={pdfViewerStyle.h4}>
                ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐԻ ՀԱՆՁՆՄԱՆ – ԸՆԴՈՒՆՄԱՆ ԱԿՏ
              </Text>
              {/*@ts-ignore*/}
              <Text style={pdfViewerStyle.body1}>{actContent?.date ?? ''}</Text>
              <Text style={pdfViewerStyle.body1}>կնքման ամսաթիվ</Text>
              <Text style={pdfViewerStyle.body1}>
                {
                  // @ts-ignore
                  `Սույն Ակտը ստորագրվում է ${
                    actContent?.act_number ?? ''
                  } Պայմանագրի Կողմերի միջեւ, որի համաձայն\``
                }
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {`(Ա) ԴԻԼԻՋԱՆ ԻՆՏԵՐՆԵՅՇՆԼ ՍՔՈՒԼ ՕՖ ԱՐՄԵՆԻԱ Հիմնադրամը ոչ առեւտրային կազմակերպություն, հիմնադրված եւ գործող համաձայն Հայաստանի Հանրապետության օրենսդրության, գտնվելու վայր՝ Գետափնյա փ. շ. 7, Դիլիջան, 3903, Տավուշ, Հայաստան, ի դեմս տնօրեն Գաբրիել Էրնեստո Աբադ Ֆերնանդեսի, ով գործում է կանոնադրության հիման վրա (այսուհետ՝ «Պատվիրատու»), մի կողմից, եւ`}
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {actContent?.personal_info_am ?? ''}
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {`Հոդված 1. Համաձայն Պայմանագրի՝ Կատարողը Պատվիրատուին է հանձնում հետեւյալ Ծառայությունները.`}
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {
                  // @ts-ignore
                  `«Ծառայություններ» - Համաձայն ${
                    actContent?.act_number ?? ''
                  } Պայմանագրի`
                }
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {actContent?.price_info_am ?? ''}
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {`Հոդված 3. Պատվիրատուն հաստատում է, որ ընդունում է մատուցված Ծառայությունները եւ չունի որեւէ առարկություն: Կատարողը հաստատում է, որ չունի որեւէ առարկություն կապված Պատվիրատուի կողմից իր պարտականությունների կատարման:`}
              </Text>
            </View>
            <View style={pdfViewerStyle.col6}>
              <Text style={pdfViewerStyle.h4}>SERVICE HANDOVER ACT</Text>
              {/*@ts-ignore*/}
              <Text style={pdfViewerStyle.body1}>{actContent?.date ?? ''}</Text>
              <Text style={pdfViewerStyle.body1}>Date of signing</Text>
              <Text style={pdfViewerStyle.body1}>
                {
                  // @ts-ignore
                  `This Act is concluded by and between the Parties to the Agreement ${
                    actContent?.act_number ?? ''
                  } according to which:`
                }
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {`(A) DILIJAN INTERNATIONAL SCHOOL OF ARMENIA Foundation, non-profit organization, established and existing in accordance with the legislation of the Republic of Armenia, with the registered office located at: 7 Getapnya Street, 3903 Dilijan, Armenia, and represented by its Director Gabriel Ernesto Abad Fernandez, acting by virtue of the Charter (hereinafter referred to as the “Client”) on the one side, and`}
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {actContent?.personal_info_en ?? ''}
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {`Article 1. Pursuant to the Agreement, the Provider hands over to the Client the Services:`}
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {
                  // @ts-ignore
                  `“Services”–AccordingtoAgreement ${
                    actContent?.act_number ?? ''
                  }`
                }
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {actContent?.price_info_en ?? ''}
              </Text>
              <Text style={pdfViewerStyle.body1}>
                {`Article 3. The Client confirms that it accepts the Services and does not have any objections. The Provider confirms that it does not have any objections as to the performance of its obligations by the Client.`}
              </Text>
            </View>
          </View>
          <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
            <View style={pdfViewerStyle.col6Arm}>
              <Text style={pdfViewerStyle.h4}>ԿԱՏԱՐՈՂ</Text>
              <Text style={pdfViewerStyle.h4}>
                ԴԻԼԻՋԱՆ ԻՆՏԵՐՆԵՅՇՆԼ ՍՔՈՒԼ ՕՖ ԱՐՄԵՆԻԱ
              </Text>
              <Text style={pdfViewerStyle.h4}>Հիմնադրամ</Text>
              <Text style={pdfViewerStyle.h4}>ՀՎՀՀ՝ 00108599</Text>
              {actContent?.signature_side1_act && (
                <View style={pdfViewerStyle.col4}>
                  <Image
                    // @ts-ignore
                    src={actContent.signature_side1_act}
                    style={{ height: 48 }}
                  />
                </View>
              )}
              <Text style={pdfViewerStyle.body1}>
                {`Տնօրեն՝ Գաբրիել Էրնեստո Աբադ Ֆերնանդես`}
              </Text>
              {parseActRequisites('am')}
              {actContent?.signature_side2_act && (
                <View style={pdfViewerStyle.col4}>
                  <Image
                    // @ts-ignore
                    src={actContent.signature_side2_act}
                    style={{ height: 48 }}
                  />
                </View>
              )}
            </View>
            <View style={pdfViewerStyle.col6}>
              <Text style={pdfViewerStyle.h4}>THE CLIENT</Text>
              <Text style={pdfViewerStyle.h4}>
                DILIJAN INTERNATIONAL SCHOOL OF ARMENIA
              </Text>
              <Text style={pdfViewerStyle.h4}>Foundation</Text>
              <Text style={pdfViewerStyle.h4}>TIN: 00108599</Text>
              {actContent?.signature_side1_act && (
                <View style={pdfViewerStyle.col4}>
                  <Image
                    // @ts-ignore
                    src={actContent.signature_side1_act}
                    style={{ height: 48 }}
                  />
                </View>
              )}
              <Text style={pdfViewerStyle.body1}>
                {`Director: Gabriel Ernesto Abad Fernandez`}
              </Text>
              {parseActRequisites('en')}
              {actContent?.signature_side2_act && (
                <View style={pdfViewerStyle.col4}>
                  <Image
                    // @ts-ignore
                    src={actContent.signature_side2_act}
                    style={{ height: 48 }}
                  />
                </View>
              )}
            </View>
          </View>
        </Page>
      </Document>
    );
  }
  return (
    <Document>
      <Page size="A4" style={pdfViewerStyle.page} />
    </Document>
  );
}

export default PdfContentViewer;
