/**
 * @description - Card Application button component.
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

import { ReactNode } from 'react';
// Material UI's core components
import { Typography, Box, Button } from '@mui/material';
// Routing
import { Link as RouterLink } from 'react-router-dom';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Local styles for the component
import { RootStyle } from './cardApplicationButtonStyles';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The application button component's interface.
 */
type CardApplicationButtonProps = {
  icon: ReactNode;
  title: string;
  path?: string;
  subtitle?: string;
};

/**
 * @description - The Application Button component's view.
 * @param icon - The icon or illustration component
 * @param title - title of the button
 * @param path - the path to redirect
 * @param subtitle - sub-title or description of the component
 * @constructor
 */
export default function CardApplicationButton({
  icon,
  title,
  path,
  subtitle
}: CardApplicationButtonProps) {
  return (
    <RootStyle>
      <div>
        <Typography variant="h3">{title}</Typography>
        {subtitle && (
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
            {subtitle}
          </Typography>
        )}
        {path && (
          <Button
            fullWidth
            variant="contained"
            sx={{ marginTop: 2 }}
            href={path}
            target={'_blank'}
          >
            Login
          </Button>
        )}
      </div>
      <Box
        sx={{
          width: 120,
          height: 120,
          lineHeight: 0,
          borderRadius: '50%',
          bgcolor: 'background.neutral'
        }}
      >
        {icon}
      </Box>
    </RootStyle>
  );
}
