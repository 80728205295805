/**
 * @description - Interface that changing the title of the page. Can be passed
 * to any upper level component. In this project it is used mostly in
 * Material UI's styled. Title changes are done through helmet module.
 * Also passing the user path into Google's analytic.
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { forwardRef, useEffect, useCallback, ReactNode } from 'react';
// If we are doing anything asynchronous on the server,
// we need Helmet to encapsulate data on a per-request basis,
// this package does just that.
import { Helmet } from 'react-helmet-async';
// Browser path tracking
import { useLocation } from 'react-router-dom';
// Material UI's components
import { Box, BoxProps } from '@mui/material';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Utils
import track from 'utils/analytics';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

// Page properties interface
interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
}

/**
 * @description - The page adHoc
 */
const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', ...other }, ref) => {
    const { pathname } = useLocation();

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: pathname
      });
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </Box>
    );
  }
);

export default Page;
