/**
 * @description - Redux store's root reducer. Combines all selected reducers
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Redux & Redux storage
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

// Configuration for the persistor
const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

// Root reducer - ToDo add slices
const rootReducer = combineReducers({});

// Export
export { rootPersistConfig, rootReducer };
