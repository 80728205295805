/**
 * @description - The dashboard nav bar
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Icons
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// Material UI
import { Box, Stack, IconButton } from '@mui/material';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Local styles
import { RootStyle, ToolbarStyle } from './dashboardNavBarStyles';
// Hooks
import { useCollapseDrawer } from 'hooks';
// Core components
import { MHidden } from 'components';
// import Searchbar from '../searchbar';
// import LanguagePopover from '../../language';
// import NotificationsPopover from '../notifications';
// import AccountPopover from '../account';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The interface for the dashboard navbar
 */
type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

/**
 * @description - The dashboard nav bar view
 * @param onOpenSidebar
 * @constructor
 */
export default function DashboardNavbar({
  onOpenSidebar
}: DashboardNavbarProps) {
  // Getting collapse drawer status
  const { isCollapse } = useCollapseDrawer();
  // The view
  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - 102px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        {/*<Searchbar />*/}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/*<LanguagePopover />*/}
          {/*<NotificationsPopover />*/}
          {/*<AccountPopover />*/}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
