/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 * ToDo add detailed description
 * ToDo add react-router V6
 * ToDo add react-router-dom V6
 * ToDo add <PrivateRoute https://stackoverflow.com/questions/62384395/protected-route-with-react-router-v6
 * ToDo - add redirect to the requested page, so the user will automatically
 *  redirect to the page from where they came
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Types
import { RouteItem } from '@types';
// Components
import {
  Loadable,
  // Root paths
  PATH_AUTH,
  ROOTS_HOME,
  // Auth paths
  PATH_LOGIN,
  PATH_REGISTER,
  PATH_LOGIN_UNPROTECTED,
  PATH_REGISTER_UNPROTECTED,
  PATH_RESET_PASSWORD,
  PATH_VERIFY_PASSWORD
} from 'routes';
// Guards
import { GuestGuard } from '../guards';

// ========================================================================== //
// ================================= Pages ================================== //
// ========================================================================== //

// Authentication Pages
const LoginPassword = Loadable(
  lazy(() => import('../pages/auth/login-password'))
);
const LoginEmailLink = Loadable(
  lazy(() => import('../pages/auth/login-email-link'))
);
const Register = Loadable(lazy(() => import('../pages/auth/login-password')));
const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/login-password'))
);
const VerifyCode = Loadable(lazy(() => import('../pages/auth/login-password')));

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Authentication routes list, contains list of RouteItem type
 * objects
 */
const authRoutes: RouteItem = {
  path: PATH_AUTH.root,
  children: [
    { element: <Navigate to={PATH_AUTH.login} replace /> },
    {
      path: PATH_LOGIN,
      element: (
        <GuestGuard path={ROOTS_HOME}>
          <LoginPassword />
        </GuestGuard>
      )
    },
    {
      path: PATH_REGISTER,
      element: (
        <GuestGuard path={ROOTS_HOME}>
          <Register />
        </GuestGuard>
      )
    },
    {
      path: PATH_LOGIN_UNPROTECTED,
      element: (
        <GuestGuard path={ROOTS_HOME}>
          <LoginPassword />
        </GuestGuard>
      )
    },
    {
      path: PATH_REGISTER_UNPROTECTED,
      element: (
        <GuestGuard path={ROOTS_HOME}>
          <Register />
        </GuestGuard>
      )
    },
    {
      path: PATH_RESET_PASSWORD,
      element: (
        <GuestGuard path={ROOTS_HOME}>
          <ResetPassword />
        </GuestGuard>
      )
    },
    {
      path: PATH_VERIFY_PASSWORD,
      element: (
        <GuestGuard path={ROOTS_HOME}>
          <VerifyCode />
        </GuestGuard>
      )
    }
  ]
};

export default authRoutes;
