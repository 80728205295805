/**
 * @description - The contracts list view component.
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

import { styled } from '@mui/material/styles';
// React
import { useEffect, useState, useRef } from 'react';
// Material UI's components
import {
  Box,
  Card,
  Menu,
  Stack,
  Table,
  Avatar,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  Stepper,
  StepLabel,
  Step,
  StepConnector
} from '@mui/material';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import { useTheme } from '@mui/material/styles';
// Firestore
import {
  getFirestore,
  collection,
  doc,
  getDocFromCache,
  getDocs,
  query,
  where,
  updateDoc,
  orderBy
} from 'firebase/firestore';
import { Icon } from '@iconify/react';
import downloadFill from '@iconify/icons-eva/download-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Local components
import { Scrollbar, Label, MIconButton } from 'components';
// Hooks
import { useAuth } from 'hooks';
import { ContractSignPage } from 'pages';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

type ContractType = {
  id: string;
  counterparty: string;
  signature1: string;
  signature2: string;
  contractId: string;
  name: string;
  counterpartyName?: string;
  confirmation_part1?: string;
  confirmation_part2?: string;
};

type MoreMenuButtonProps = {
  onMenuClick: () => void;
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  top: 10,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  '& .MuiStepConnector-line': {
    borderTopWidth: 2,
    borderColor: theme.palette.divider
  },
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main
    }
  }
}));

function QontoStepIcon({
  active,
  completed
}: {
  active: boolean;
  completed: boolean;
}) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'text.disabled'
      }}
    >
      {completed ? (
        <Box
          component={Icon}
          icon={checkmarkFill}
          sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }}
        />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor'
          }}
        />
      )}
    </Box>
  );
}

function MoreMenuButton({ onMenuClick }: MoreMenuButtonProps) {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <>
        <MIconButton ref={menuRef} size="large" onClick={() => setOpen(true)}>
          <Icon icon={moreVerticalFill} width={20} height={20} />
        </MIconButton>
      </>
      <Menu
        open={open}
        anchorEl={menuRef.current}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={onMenuClick}>
          <Icon icon={downloadFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Open
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default function ContractsListView() {
  // Current data list
  const [values, setValues] = useState<ContractType[]>([]);
  // Getting current user
  const { user } = useAuth();
  // Getting firebase db object
  const firebaseDb = getFirestore();

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const [currentDocument, setCurrentDocument] = useState<string>('');
  const [currentDocumentContent, setCurrentDocumentContent] = useState<any>({});

  const onOpenDocument = (contractId: string, content: any) => {
    console.log(`active document is ${currentDocument}`);
    setCurrentDocument(contractId);
    setCurrentDocumentContent(content);
  };

  /**
   * @description - The method is generating the view of row
   * @param row
   */
  const getRowView = (row: ContractType) => {
    return (
      <TableRow key={row.id}>
        <TableCell>{`${row.name}`}</TableCell>
        <TableCell>
          <Label
            variant={isLight ? 'ghost' : 'filled'}
            color={
              row?.signature1
                ? 'default'
                : row?.signature2
                ? 'success'
                : 'error'
            }
          >
            {row?.signature1
              ? 'Completed'
              : row?.signature2
              ? 'Signed By'
              : 'In process'}
          </Label>
        </TableCell>
        <TableCell>{row?.counterpartyName}</TableCell>
        <TableCell align="right">
          <MoreMenuButton onMenuClick={() => onOpenDocument(row.id, row)} />
        </TableCell>
      </TableRow>
    );
  };
  /**
   * @description - Initializing data-fetching
   */
  useEffect(() => {
    const temporaryReference = collection(firebaseDb, 'temporary');
    const queryAction = query(
      temporaryReference,
      where('side1', '==', user.id)
    );
    getDocs(queryAction).then((querySnapshot) => {
      const listOfContracts: ContractType[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data) {
          listOfContracts.push({
            id: data.id,
            counterparty: data.requisites.en[0],
            contractId: data.id,
            name: data.fields.en[0],
            signature1: data.signature_side1,
            signature2: data.signature_side2,
            confirmation_part1: data.confirmation_part1
              ? data.confirmation_part1
              : null,
            confirmation_part2: data.confirmation_part2
              ? data.confirmation_part2
              : null
          });
        }
      });
      if (listOfContracts.length > 0) {
        setValues(listOfContracts);
      }
    });

    const queryAssignedAction = query(
      temporaryReference,
      where('assigned', '==', 'aphSYYQUHpMH847kGJsg6XjJhsO2'),
      orderBy('signature_side2', 'desc')
    );
    getDocs(queryAssignedAction)
      .then((querySnapshot) => {
        console.log('fetching data');
        const listOfContracts: ContractType[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data) {
            console.log('data is', data);
            listOfContracts.push({
              id: data.id,
              counterparty: data.requisites.en[0],
              contractId: data.id,
              name: data.fields.en[0],
              signature1: data.signature_side1,
              signature2: data.signature_side2,
              counterpartyName: data.requisites.en[0],
              confirmation_part1: data.confirmation_part1
                ? data.confirmation_part1
                : null,
              confirmation_part2: data.confirmation_part2
                ? data.confirmation_part2
                : null
            });
          }
        });
        if (listOfContracts.length > 0) {
          setValues(listOfContracts);
        }
      })
      .catch((error) => console.log(error));

    const queryConfirmAction = query(
      temporaryReference,
      where('confirm1', '==', user.id)
    );
    getDocs(queryConfirmAction).then((querySnapshot) => {
      const listOfContracts: ContractType[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data) {
          listOfContracts.push({
            id: data.id,
            counterparty: data.requisites.en[0],
            contractId: data.id,
            name: data.fields.en[0],
            signature1: data.signature_side1,
            signature2: data.signature_side2,
            confirmation_part1: data.confirmation_part1
              ? data.confirmation_part1
              : null,
            confirmation_part2: data.confirmation_part2
              ? data.confirmation_part2
              : null
          });
        }
      });
      if (listOfContracts.length > 0) {
        setValues(listOfContracts);
      }
    });

    const queryConfirm2Action = query(
      temporaryReference,
      where('confirm2', '==', user.id)
    );
    getDocs(queryConfirm2Action).then((querySnapshot) => {
      const listOfContracts: ContractType[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data) {
          listOfContracts.push({
            id: data.id,
            counterparty: data.requisites.en[0],
            contractId: data.id,
            name: data.fields.en[0],
            signature1: data.signature_side1,
            signature2: data.signature_side2,
            confirmation_part1: data.confirmation_part1
              ? data.confirmation_part1
              : null,
            confirmation_part2: data.confirmation_part2
              ? data.confirmation_part2
              : null
          });
        }
      });
      if (listOfContracts.length > 0) {
        setValues(listOfContracts);
      }
    });
  }, []);

  if (currentDocument && currentDocument.length > 0) {
    const getActiveStep = () => {
      console.log(currentDocumentContent);
      if (currentDocumentContent?.confirmation_part2) {
        return 3;
      }

      if (currentDocumentContent?.confirmation_part1) {
        return 2;
      }

      return 1;
    };
    return (
      <>
        {!currentDocumentContent?.signature2 && (
          <Stepper
            alternativeLabel
            activeStep={getActiveStep()}
            connector={<QontoConnector />}
            sx={{ mt: 2 }}
          >
            {['Legal Review/Confirmation', 'Signature'].map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={QontoStepIcon}
                  sx={{
                    '& .MuiStepLabel-label': {
                      typography: 'subtitle2',
                      color: 'text.disabled'
                    }
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <Button onClick={() => setCurrentDocument('')}>Back</Button>
        <ContractSignPage selectedDocument={currentDocument} />
      </>
    );
  }
  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader title="Available Contracts" sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 240 }}>Contract</TableCell>
                <TableCell sx={{ minWidth: 120 }}>Status</TableCell>
                <TableCell sx={{ minWidth: 160 }}>Counterparty</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>{values.map((row) => getRowView(row))}</TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
