/**
 * @description - React hook method for settings provider
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

import { useContext } from 'react';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

import { SettingsContext } from '../contexts';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Settings context method.
 */
const useSettings = () => useContext(SettingsContext);

export default useSettings;
