/**
 * @description - The configuration file for the side bar
 */

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Paths
import { PATH_ADMIN } from 'routes';
// Icons
import {
  elements,
  page,
  team,
  process,
  forms,
  user,
  departments,
  budgets,
  payments,
  invoices,
  box,
  stock,
  requests,
  authorizations,
  documents,
  archive,
  charts,
  reports2,
  analytics,
  store
} from 'components';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

export default [
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_ADMIN.dashboard,
        icon: elements
      }
    ]
  },
  {
    subheader: 'processes',
    items: [
      {
        title: 'forms',
        path: PATH_ADMIN.processes.forms,
        icon: forms
      },
      {
        title: 'workflows',
        path: PATH_ADMIN.processes.flows,
        icon: process
      }
    ]
  },
  {
    subheader: 'personnel',
    items: [
      {
        title: 'departments',
        path: PATH_ADMIN.personnel.departments,
        icon: departments
      },
      {
        title: 'groups',
        path: PATH_ADMIN.personnel.groups,
        icon: team
      },
      {
        title: 'team',
        path: PATH_ADMIN.personnel.team,
        icon: user
      }
    ]
  },
  {
    subheader: 'finance',
    items: [
      {
        title: 'budgets',
        path: PATH_ADMIN.finance.budgets,
        icon: budgets
      },
      {
        title: 'payments',
        path: PATH_ADMIN.finance.payments,
        icon: payments
      },
      {
        title: 'invoices',
        path: PATH_ADMIN.finance.invoices,
        icon: invoices
      }
    ]
  },
  {
    subheader: 'items',
    items: [
      {
        title: 'stocks',
        path: PATH_ADMIN.inventory.stocks,
        icon: stock
      },
      {
        title: 'goods',
        path: PATH_ADMIN.inventory.goods,
        icon: box
      }
    ]
  },
  {
    subheader: 'providers',
    items: [
      {
        title: 'vendors',
        path: PATH_ADMIN.vendors.manage,
        icon: store
      }
    ]
  },
  {
    subheader: 'requests',
    items: [
      {
        title: 'goods requests',
        path: PATH_ADMIN.requests.goods_requests,
        icon: requests
      }
    ]
  },
  {
    subheader: 'documents',
    items: [
      {
        title: 'authorizations',
        path: PATH_ADMIN.documents.authorizations,
        icon: authorizations
      },
      {
        title: 'contracts',
        path: PATH_ADMIN.documents.processingContracts,
        icon: documents
      },
      {
        title: 'archive',
        path: PATH_ADMIN.documents.archiveContracts,
        icon: archive
      }
    ]
  },
  {
    subheader: 'reports',
    items: [
      {
        title: 'procurement',
        path: PATH_ADMIN.reports.procurement,
        icon: charts
      },
      {
        title: 'contracts',
        path: PATH_ADMIN.reports.contracts,
        icon: reports2
      },
      {
        title: 'financial',
        path: PATH_ADMIN.reports.financial,
        icon: analytics
      }
    ]
  }
];
