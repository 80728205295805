/**
 * @description - The Dashboard Layout view
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { useState } from 'react';
// React routing
import { Outlet } from 'react-router-dom';
// Material UI's
// material
import { useTheme } from '@mui/material/styles';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Styles
import { DashboardLayoutStyle, RootStyle } from './dashboardLayoutStyle';
// Hooks
import { useCollapseDrawer } from 'hooks';
// Core components
import { DashboardNavbar, DashboardSidebar } from 'components';
// Side bar configs
import { adminSidebarConfig } from 'routes';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The Dashboard layout view
 * @constructor
 */
export default function MainLayout() {
  // Getting current theme
  const theme = useTheme();
  // Getting collapse click method for the side bar
  const { collapseClick } = useCollapseDrawer();
  // Internal state to save the opened status of side bar
  const [open, setOpen] = useState(false);
  // The view
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        navConfig={adminSidebarConfig}
      />
      <DashboardLayoutStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Outlet />
      </DashboardLayoutStyle>
    </RootStyle>
  );
}
