/**
 * @description - Style component for Dashboard page.
 * ToDo add detailed description
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Material UI's style
import { alpha, styled } from '@mui/material/styles';
// Material UI's core components
import { AppBar, Toolbar } from '@mui/material';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The root style of the component
 */
export const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: { width: `calc(100% - 281px)` }
}));

/**
 * @description - The toolbar wrapper style for the component
 */
export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: 64,
  [theme.breakpoints.up('lg')]: {
    minHeight: 92,
    padding: theme.spacing(0, 5)
  }
}));
