/**
 * @description - The redux store file. Building and exporting the redux store
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Redux
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Root reducer
import { rootPersistConfig, rootReducer } from './rootReducer';

// Creating the redux store
const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  })
});

// Persistor
const persistor = persistStore(store);

// Dispatches & Selectors
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
const { dispatch } = store;
const useSelector = useReduxSelector;
const useDispatch = () => useReduxDispatch<AppDispatch>();

// Export
export { store, persistor, dispatch, useSelector, useDispatch };
