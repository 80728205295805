/**
 * @description - The utility to parse document content from the fetched data.
 * Needed to parse the content object of type TypeDocumentContent and
 * TypeDocumentValue to react object or pdf viewer object
 */

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Types of the document content and values
import { TypeDocumentContent } from '@types';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The method is generating avatar name and color based on it's
 * name
 * @param documentContent - The list of document contents
 * @param language
 */
export function parseDocumentTitles(
  documentContent: TypeDocumentContent[] | null,
  language: 'en' | 'ru' | 'am' // Contains the language of the value
) {
  if (documentContent) {
    return documentContent.filter(
      (currentDocument) =>
        currentDocument.type === 'title' &&
        currentDocument.language === language
    );
  }
  return null;
}

/**
 * @description - The method is generating avatar name and color based on it's
 * name
 * @param documentContent - The list of document contents
 * @param language
 */
export function parseDocumentContent(
  documentContent: TypeDocumentContent[] | null,
  language: 'en' | 'ru' | 'am' // Contains the language of the value
) {
  if (documentContent) {
    return documentContent.filter(
      (currentDocument) =>
        (currentDocument.type === 'paragraph' ||
          currentDocument.type === 'subtitle') &&
        currentDocument.language === language
    );
  }
  return null;
}
