/**
 * @description - The Contract signature page view
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// Material UI's
import {
  Box,
  Grid,
  Card,
  Container,
  DialogTitle,
  Typography,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Pdf viewer & download
import { PDFDownloadLink } from '@react-pdf/renderer';
// Icons
import { Icon } from '@iconify/react';
import downloadFill from '@iconify/icons-eva/download-fill';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Local components
import {
  Page,
  Label,
  DialogAnimate,
  SignatureDrawer,
  PdfContentViewer
} from 'components';
// Styles
import { ContractSignPageToolbarRootStyle } from './contractSignPageStyles';
import { parseDocumentTitles, parseDocumentContent } from 'utils/parseDocument';
// ToDo remove the steacked content
import content from '../../constants/contractContent';
// Firestore
import {
  getFirestore,
  collection,
  doc,
  getDocFromCache,
  getDocs,
  query,
  where,
  updateDoc
} from 'firebase/firestore';
import { useAuth } from 'hooks';
import { ActPdfContentViewer } from '../../components/pdf/pdf-viewer';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The method is getting the document titles in a form of
 * Typography objects list
 * @param language,
 * @param data,
 */
const getDocumentTitles = (language: 'am' | 'en' | 'ru', data: string[]) => {
  const finalTitles = parseDocumentTitles(content, language);
  if (finalTitles && finalTitles.length > 0) {
    return finalTitles.map((value, index) => {
      let finalValue = value.value;
      if (data) {
        const dataList = Object.values(data);
        value.value
          .split(' ')
          .filter((item) => item.includes('bisflow.value.'))
          .map((inputVariable) => {
            const valueIndex = parseInt(
              inputVariable.replace('bisflow.value.', '')
            );
            const inputValue = dataList[valueIndex];
            if (inputValue) {
              finalValue = finalValue.replace(inputVariable, inputValue);
            }
          });
      }
      return (
        <Typography key={`title_${language}_${index}`} variant="h6">
          {finalValue}
        </Typography>
      );
    });
  }
  return <Typography variant="h6">No Title</Typography>;
};

/**
 * @description - The method is getting the document content in a form of
 * Typography objects list
 * @param language
 * * @param data,
 */
const getDocumentContent = (language: 'am' | 'en' | 'ru', data: string[]) => {
  const finalContent = parseDocumentContent(content, language);
  if (finalContent && finalContent.length > 0) {
    return finalContent.map((value, index) => {
      let finalValue = value.value;
      if (data) {
        const dataList = Object.values(data);
        value.value
          .split(' ')
          .filter((item) => item.includes('bisflow.value.'))
          .map((inputVariable) => {
            const valueIndex = parseInt(
              inputVariable.replace('bisflow.value.', '')
            );
            const inputValue = dataList[valueIndex];
            if (inputValue) {
              finalValue = finalValue.replace(inputVariable, inputValue);
            }
          });
      }
      if (value.type === 'paragraph') {
        return (
          <Typography
            key={`title_${language}_${index}`}
            variant="body2"
            paragraph
          >
            {finalValue}
          </Typography>
        );
      } else {
        return (
          <Typography
            key={`title_${language}_${index}`}
            variant="subtitle1"
            paragraph
          >
            {finalValue}
          </Typography>
        );
      }
    });
  }
  return (
    <Typography paragraph variant="body2">
      No Content
    </Typography>
  );
};

type ContractSignPageProps = {
  selectedDocument?: string;
};

export default function ContractSignPage({
  selectedDocument
}: ContractSignPageProps) {
  const { id } = useParams();
  const { user } = useAuth();
  /**
   * @description - The firestore db instance.
   */
  const firebaseDb = getFirestore();

  const getWidth = () => {
    return window.innerWidth;
  };

  const getHeight = () => {
    return window.innerHeight - 50;
  };

  // Signature Dialog visibility state
  const [showDialog, setShowDialog] = useState(false);
  const [showFileDialog, setShowFileDialog] = useState(true);
  const [side, setSide] = useState('side0');
  const [documentData, setDocumentData] = useState({});
  const [actData, setActData] = useState({});

  useEffect(() => {
    const temporaryReference = collection(firebaseDb, 'temporaryActs');
    let queryAction;
    if (documentData) {
      queryAction = query(
        temporaryReference,
        // @ts-ignore
        where('contract_id', '==', documentData?.id ?? 'none')
      );

      getDocs(queryAction).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          setActData(doc.data());
        });
      });
    }
  }, [documentData]);

  /**
   * @description - The method is toggling the signature dialog
   */
  const toggleSignatureDialogView = (selectedSide: string | null) => {
    if (selectedSide) {
      setSide(selectedSide);
    }
    setShowDialog(!showDialog);
  };

  /**
   * @description - The method is toggling the signature dialog
   */
  const toggleFileDialogView = () => {
    setShowFileDialog(!showFileDialog);
  };

  const onDialogAction = (base64Image: any) => {
    if (side === 'side2_act' || side === 'side1_act') {
      // @ts-ignore
      if (actData?.uid && base64Image) {
        // @ts-ignore
        const documentRef = doc(firebaseDb, 'temporaryActs', actData.uid);
        updateDoc(documentRef, {
          [`signature_${side}`]: base64Image,
          [`signed_${side}`]: Date.now().toLocaleString()
        }).then(() => {
          try {
            window.location.reload();
          } catch (e) {
            toggleSignatureDialogView(null);
          }
        });
      }
    } else {
      // @ts-ignore
      if (documentData?.id && base64Image) {
        // @ts-ignore
        const documentRef = doc(firebaseDb, 'temporary', documentData.id);
        updateDoc(documentRef, {
          [`signature_${side}`]: base64Image,
          [`signed_${side}`]: Date.now().toLocaleString()
        }).then(() => {
          try {
            window.location.reload();
          } catch (e) {
            toggleSignatureDialogView(null);
          }
        });
      }
    }
    toggleSignatureDialogView(null);
  };

  useEffect(() => {
    const temporaryReference = collection(firebaseDb, 'temporary');
    let queryAction;
    if (selectedDocument || id) {
      queryAction = query(
        temporaryReference,
        where('id', '==', id ?? selectedDocument)
      );
    } else {
      queryAction = query(temporaryReference, where('side1', '==', user.id));
    }
    getDocs(queryAction).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        setDocumentData(doc.data());
      });
    });
    if (!id || id?.length < 3) {
      const querySide2Action = query(
        temporaryReference,
        where('side2', '==', user.id)
      );
      getDocs(querySide2Action).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          setDocumentData(doc.data());
        });
      });
    }
  }, []);

  let fileName = 'NA';
  const title = parseDocumentTitles(content, 'en');
  // @ts-ignore
  if (documentData?.fields?.en?.[0] && title && title[0]?.value) {
    fileName = `${title[0].value.replace(
      'bisflow.value.0',
      // @ts-ignore
      documentData.fields.en[0]
    )} ${title[1].value}`;
  }

  const confirm = (confirmationSide: string) => {
    if (confirmationSide && documentData) {
      // @ts-ignore
      const documentRef = doc(firebaseDb, 'temporary', documentData.id);
      updateDoc(documentRef, {
        [`${confirmationSide}`]: Date.now().toLocaleString()
      }).then(() => {
        try {
          window.location.reload();
        } catch (e) {
          console.log('completed');
        }
      });
    }
  };

  const parseActView = () => {
    // @ts-ignore
    if (actData && actData.act !== false) {
      console.log(actData);
      const actView: any[] = [];
      actView.push(
        <Grid item xs={12} sm={6}>
          <Grid container sx={{ mb: 5 }}>
            {/* Title */}
            <Grid item xs={12} sm={12} sx={{ mb: 3 }}>
              {/* @ts-ignore */}
              <Typography key={`act_${actData?.act_number}`} variant="h6">
                ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐԻ ՀԱՆՁՆՄԱՆ – ԸՆԴՈՒՆՄԱՆ ԱԿՏ
              </Typography>
            </Grid>
            {/* Place */}
            <Grid item xs={12} sm={6} sx={{ mb: 6 }}>
              <Typography variant="body2">{'Դիլիջան'}</Typography>
              <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                կնքման վայր
              </Typography>
            </Grid>
            {/* Time */}
            <Grid item xs={12} sm={6} sx={{ mb: 6 }}>
              <Typography variant="body2">
                {/* @ts-ignore */}
                {actData?.date ?? ''}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                կնքման ամսաթիվ
              </Typography>
            </Grid>
            {/* ------- Content -------  */}
            <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
              {/* @ts-ignore */}
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {`Սույն Ակտը ստորագրվում է ${actData?.act_number} Պայմանագրի
                Կողմերի միջեւ, որի համաձայն\``}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {`(Ա) ԴԻԼԻՋԱՆ ԻՆՏԵՐՆԵՅՇՆԼ ՍՔՈՒԼ ՕՖ ԱՐՄԵՆԻԱ Հիմնադրամը ոչ առեւտրային կազմակերպություն, հիմնադրված եւ գործող համաձայն Հայաստանի Հանրապետության օրենսդրության, գտնվելու վայր՝ Գետափնյա փ. շ. 7, Դիլիջան, 3903, Տավուշ, Հայաստան, ի դեմս տնօրեն Գաբրիել Էրնեստո Աբադ Ֆերնանդեսի, ով գործում է կանոնադրության հիման վրա (այսուհետ՝ «Պատվիրատու»), մի կողմից, եւ`}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {actData?.personal_info_am ?? ''}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {`Հոդված 1. Համաձայն Պայմանագրի՝ Կատարողը Պատվիրատուին է հանձնում հետեւյալ Ծառայությունները.`}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {`«Ծառայություններ» - Համաձայն ${actData?.act_number} Պայմանագրի`}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {actData?.price_info_am ?? ''}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {`Հոդված 3. Պատվիրատուն հաստատում է, որ ընդունում է մատուցված Ծառայությունները եւ չունի որեւէ առարկություն: Կատարողը հաստատում է, որ չունի որեւէ առարկություն կապված Պատվիրատուի կողմից իր պարտականությունների կատարման:`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
      actView.push(
        <Grid item xs={12} sm={6}>
          <Grid container sx={{ mb: 5 }}>
            {/* Title */}
            <Grid item xs={12} sm={12} sx={{ mb: 3 }}>
              {/* @ts-ignore */}
              <Typography key={`act_${actData?.act_number}`} variant="h6">
                SERVICE HANDOVER ACT
              </Typography>
            </Grid>
            {/* Place */}
            <Grid item xs={12} sm={6} sx={{ mb: 6 }}>
              <Typography variant="body2">{'Dilijan'}</Typography>
              <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                place of conclusion
              </Typography>
            </Grid>
            {/* Time */}
            <Grid item xs={12} sm={6} sx={{ mb: 6 }}>
              <Typography variant="body2">
                {/* @ts-ignore */}
                {actData?.date ?? ''}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                date of conclusion
              </Typography>
            </Grid>
            {/* ------- Content -------  */}
            <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
              {/* @ts-ignore */}
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {`This Act is concluded by and between the Parties to the ${actData?.act_number} according to which:`}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {`(A) DILIJAN INTERNATIONAL SCHOOL OF ARMENIA Foundation, non-profit organization, established and existing in accordance with the legislation of the Republic of Armenia, with the registered office located at: 7 Getapnya Street, 3903 Dilijan, Armenia, and represented by its Director Gabriel Ernesto Abad Fernandez, acting by virtue of the Charter (hereinafter referred to as the “Client”) on the one side, and`}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {actData?.personal_info_en ?? ''}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {`Article 1. Pursuant to the Agreement, the Provider hands over to the Client the Services:`}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {`“Services” – According to Agreement ${actData?.act_number}`}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {actData?.price_info_en ?? ''}
              </Typography>
              <Typography variant="body2" paragraph>
                {/*@ts-ignore*/}
                {`Article 3. The Client confirms that it accepts the Services and does not have any objections. The Provider confirms that it does not have any objections as to the performance of its obligations by the Client.`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
      actView.push(
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" paragraph>
            ՊԱՏՎԻՐԱՏՈՒ
          </Typography>
          <Typography variant="subtitle1" paragraph>
            ԴԻԼԻՋԱՆ ԻՆՏԵՐՆԵՅՇՆԼ ՍՔՈՒԼ ՕՖ ԱՐՄԵՆԻԱ Հիմնադրամ
          </Typography>
          <Typography variant="subtitle1" paragraph>
            ՀՎՀՀ՝ 00108599
          </Typography>
        </Grid>
      );
      actView.push(
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" paragraph>
            THE CLIENT
          </Typography>
          <Typography variant="subtitle1" paragraph>
            DILIJAN INTERNATIONAL SCHOOL OF ARMENIA Foundation
          </Typography>
          <Typography variant="subtitle1" paragraph>
            TIN: 00108599
          </Typography>
        </Grid>
      );
      actView.push(
        <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
          {/* @ts-ignore */}
          {documentData?.side1 === user.id &&
            // @ts-ignore
            !actData?.signature_side1_act && (
              <Button
                fullWidth
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={() => toggleSignatureDialogView('side1_act')}
              >
                SIGN
              </Button>
            )}
          {/* @ts-ignore */}
          {actData?.signature_side1_act && (
            <img
              alt="..."
              style={{
                width: 80,
                height: 80
              }}
              src={
                // @ts-ignore
                actData.signature_side1_act
              }
            />
          )}
        </Grid>
      );
      actView.push(
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" paragraph>
            Տնօրեն՝ Գաբրիել Էրնեստո Աբադ Ֆերնանդես
          </Typography>
        </Grid>
      );
      actView.push(
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" paragraph>
            Director: Gabriel Ernesto Abad Fernandez
          </Typography>
        </Grid>
      );
      actView.push(
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" paragraph>
            ԿԱՏԱՐՈՂ
          </Typography>
          {/* @ts-ignore */}
          {actData?.requisites?.am.map((item, number) => (
            <Typography
              variant="subtitle1"
              paragraph
              key={`requisites_am_${number}`}
            >
              {item}
            </Typography>
          ))}
        </Grid>
      );
      actView.push(
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" paragraph>
            THE PROVIDER
          </Typography>
          {/* @ts-ignore */}
          {actData?.requisites?.en.map((item, number) => (
            <Typography
              variant="subtitle1"
              paragraph
              key={`requisites_en_${number}`}
            >
              {item}
            </Typography>
          ))}
        </Grid>
      );
      actView.push(
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          {/* @ts-ignore */}
          {documentData?.side2 === user.id &&
            // @ts-ignore
            !actData?.signature_side2_act && (
              <Button
                fullWidth
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={() => toggleSignatureDialogView('side2_act')}
              >
                SIGN
              </Button>
            )}
          {/* @ts-ignore */}
          {actData?.signature_side2_act && (
            <img
              alt="..."
              style={{
                width: 80,
                height: 80
              }}
              src={
                // @ts-ignore
                actData.signature_side2_act
              }
            />
          )}
        </Grid>
      );
      actView.push(
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          {/* @ts-ignore */}
          {documentData?.side2 === user.id &&
            // @ts-ignore
            !actData?.signature_side2_act && (
              <Button
                fullWidth
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={() => toggleSignatureDialogView('side2_act')}
              >
                SIGN
              </Button>
            )}
          {/* @ts-ignore */}
          {actData?.signature_side2_act && (
            <img
              alt="..."
              style={{
                width: 80,
                height: 80
              }}
              src={
                // @ts-ignore
                actData.signature_side2_act
              }
            />
          )}
        </Grid>
      );
      return actView;
    }
    return null;
  };

  return (
    <Page title="Bisflow: Document Signature">
      <Container>
        {/* Dialog */}
        <DialogAnimate
          open={showDialog}
          onClose={() => toggleSignatureDialogView(null)}
          fullWidth
          maxWidth="lg"
        >
          <SignatureDrawer onDialogClick={onDialogAction} />
        </DialogAnimate>
        <ContractSignPageToolbarRootStyle>
          {Object.keys(actData).length > 0 && (
            <PDFDownloadLink
              document={<ActPdfContentViewer actContent={{ ...actData }} />}
              fileName={
                // @ts-ignore
                `Handover_act_${actData?.act_number ?? 'N'}.pdf`
              }
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <LoadingButton
                  size="small"
                  loading={loading}
                  variant="contained"
                  loadingPosition="end"
                  endIcon={<Icon icon={downloadFill} />}
                >
                  Download
                </LoadingButton>
              )}
            </PDFDownloadLink>
          )}
        </ContractSignPageToolbarRootStyle>
        <Card sx={{ pt: 5, px: 5, mb: 5 }}>
          <Grid container spacing={3}>
            {/* Header */}
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Box
                component="img"
                alt="logo"
                src="/static/brands/uwcd/UWCD_LOGO_GREEN.png"
                sx={{ height: 24 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }} />
            {parseActView()}
          </Grid>
        </Card>
        {/* Toolbar */}
        <ContractSignPageToolbarRootStyle>
          {Object.keys(documentData).length > 0 && (
            <PDFDownloadLink
              document={
                <PdfContentViewer
                  documentContent={content}
                  documentExternalData={documentData}
                />
              }
              fileName={fileName}
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <LoadingButton
                  size="small"
                  loading={loading}
                  variant="contained"
                  loadingPosition="end"
                  endIcon={<Icon icon={downloadFill} />}
                >
                  Download
                </LoadingButton>
              )}
            </PDFDownloadLink>
          )}
        </ContractSignPageToolbarRootStyle>
        <Card sx={{ pt: 5, px: 5, mb: 5 }}>
          <Grid container spacing={3}>
            {/* Header */}
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Box
                component="img"
                alt="logo"
                src="/static/brands/uwcd/UWCD_LOGO_GREEN.png"
                sx={{ height: 24 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }} />
            {/*  <Box sx={{ textAlign: { sm: 'right' } }}>*/}
            {/*    <Label*/}
            {/*      color="success"*/}
            {/*      sx={{ textTransform: 'uppercase', mb: 1 }}*/}
            {/*    >*/}
            {/*      In process*/}
            {/*    </Label>*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/* Arm */}
            <Grid item xs={12} sm={6}>
              <Grid container sx={{ mb: 5 }}>
                {/* Title */}
                <Grid item xs={12} sm={12} sx={{ mb: 3 }}>
                  {/* @ts-ignore */}
                  {getDocumentTitles('am', documentData?.fields?.am)}
                </Grid>
                {/* Place */}
                <Grid item xs={12} sm={6} sx={{ mb: 6 }}>
                  <Typography variant="body2">{'ք. Երևան'}</Typography>
                  <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                    կնքման վայր
                  </Typography>
                </Grid>
                {/* Time */}
                <Grid item xs={12} sm={6} sx={{ mb: 6 }}>
                  <Typography variant="body2">
                    {/* @ts-ignore */}
                    {documentData?.date?.am ? documentData.date.am : ''}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                    կնքման ամսաթիվ
                  </Typography>
                </Grid>
                {/* ------- Content -------  */}
                <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
                  {/* @ts-ignore */}
                  {getDocumentContent('am', documentData?.fields?.am)}
                </Grid>
              </Grid>
            </Grid>
            {/* En */}
            <Grid item xs={12} sm={6} sx={{ mb: 6 }}>
              <Grid container sx={{ mb: 5 }}>
                {/* Title */}
                <Grid item xs={12} sm={12} sx={{ mb: 3 }}>
                  {/* @ts-ignore */}
                  {getDocumentTitles('en', documentData?.fields?.en)}
                </Grid>
                {/* Place */}
                <Grid item xs={12} sm={6} sx={{ mb: 6 }}>
                  <Typography variant="body2">{'Yerevan'}</Typography>
                  <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                    Place of signing
                  </Typography>
                </Grid>
                {/* Time */}
                <Grid item xs={12} sm={6} sx={{ mb: 6 }}>
                  <Typography variant="body2">
                    {/* @ts-ignore */}
                    {documentData?.date?.en ? documentData.date.en : ''}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                    Date of signing
                  </Typography>
                </Grid>
                {/* ------- Content -------  */}
                <Grid item xs={12} sm={12} sx={{ mb: 3 }}>
                  {/* @ts-ignore */}
                  {getDocumentContent('en', documentData?.fields?.en)}
                </Grid>
              </Grid>
            </Grid>
            {/* ----- Requisites Company ------  */}
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Typography variant="subtitle1" paragraph>
                ԴԻԼԻՋԱՆ ԻՆՏԵՐՆԵՅՇՆԼ ՍՔՈՒԼ ՕՖ ԱՐՄԵՆԻԱ Հիմնադրամ
              </Typography>
              <Typography variant="subtitle1" paragraph>
                ՀՎՀՀ՝ 00108599
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Typography variant="subtitle1" paragraph>
                DILIJAN INTERNATIONAL SCHOOL OF ARMENIA Foundation
              </Typography>
              <Typography variant="subtitle1" paragraph>
                TIN: 00108599
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              {/* @ts-ignore */}
              {documentData?.side1 === user.id &&
                // @ts-ignore
                !documentData?.signature_side1 && (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    onClick={() => toggleSignatureDialogView('side1')}
                  >
                    SIGN
                  </Button>
                )}
              {/* @ts-ignore */}
              {documentData?.signature_side1 && (
                <img
                  alt="..."
                  style={{
                    width: 80,
                    height: 80
                  }}
                  src={
                    // @ts-ignore
                    documentData.signature_side1
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              {/* @ts-ignore */}
              {documentData?.side1 === user.id &&
                // @ts-ignore
                !documentData?.signature_side1 && (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    onClick={() => toggleSignatureDialogView('side1')}
                  >
                    SIGN
                  </Button>
                )}
              {/* @ts-ignore */}
              {documentData?.signature_side1 && (
                <img
                  alt="..."
                  style={{
                    width: 80,
                    height: 80
                  }}
                  src={
                    // @ts-ignore
                    documentData.signature_side1
                  }
                />
              )}
            </Grid>
            {/* Requisites Persona */}
            <Grid item xs={12} sm={6} sx={{ mt: 4, mb: 2 }}>
              <Typography variant="subtitle1" paragraph>
                ԿԱՏԱՐՈՂ
              </Typography>
              {
                // @ts-ignore
                documentData?.requisites?.am?.map((item, index) => (
                  <Typography
                    key={`requisite_${item}_${index}`}
                    variant="subtitle2"
                    paragraph
                  >
                    {item}
                  </Typography>
                ))
              }
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 4, mb: 2 }}>
              <Typography variant="subtitle1" paragraph>
                THE PROVIDER
              </Typography>
              {
                // @ts-ignore
                documentData?.requisites?.en?.map((item, index) => (
                  <Typography
                    key={`requisite_${item}_${index}`}
                    variant="subtitle2"
                    paragraph
                  >
                    {item}
                  </Typography>
                ))
              }
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              {/* @ts-ignore */}
              {documentData?.side2 === user.id &&
                // @ts-ignore
                !documentData?.signature_side2 && (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    onClick={() => toggleSignatureDialogView('side2')}
                  >
                    SIGN
                  </Button>
                )}
              {/* @ts-ignore */}
              {documentData?.signature_side2 && (
                <img
                  alt="..."
                  style={{
                    width: 80,
                    height: 80
                  }}
                  src={
                    // @ts-ignore
                    documentData.signature_side2
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              {/* @ts-ignore */}
              {documentData?.side2 === user.id &&
                // @ts-ignore
                !documentData?.signature_side2 && (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    onClick={() => toggleSignatureDialogView('side2')}
                  >
                    SIGN
                  </Button>
                )}
              {/* @ts-ignore */}
              {documentData?.signature_side2 && (
                <img
                  alt="..."
                  style={{
                    width: 80,
                    height: 80
                  }}
                  src={
                    // @ts-ignore
                    documentData.signature_side2
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
              {/* @ts-ignore */}
              {documentData?.confirm1 === user.id &&
                // @ts-ignore
                !documentData?.confirmation_part1 && (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    onClick={() => confirm('confirmation_part1')}
                  >
                    CONFIRM
                  </Button>
                )}
            </Grid>
            <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
              {/* @ts-ignore */}
              {documentData?.confirm2 === user.id &&
                // @ts-ignore
                !documentData?.confirmation_part2 && (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    onClick={() => confirm('confirmation_part2')}
                  >
                    CONFIRM
                  </Button>
                )}
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}
