/**
 * @description - The login-password page's styles
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Material UI's
import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

import { Page } from 'components';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

export const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

export const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
