/**
 * @description - Settings side bar component. Displaying settings for theme
 * change, full screen change &
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { useEffect, useState } from 'react';
// Routing
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
// Material UI's
import {
  Box,
  Link,
  List,
  Stack,
  Button,
  Drawer,
  Tooltip,
  Collapse,
  Typography,
  CardActionArea,
  ListItemText,
  BoxProps
} from '@mui/material';
// Icons
import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// Style
import { alpha, useTheme } from '@mui/material/styles';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Hooks
import { useAuth, useCollapseDrawer } from 'hooks';
// Styles
import {
  RootStyle,
  AccountStyle,
  ListItemStyle,
  ListItemIconStyle,
  ListSubheaderStyle
} from './sideBarStyles';
// Core components
import { MHidden, Scrollbar, LogoBisflowGreen, MyAvatar } from 'components';
// Images
import { DocIllustration } from 'assets';

// Scroll Bar styles
import 'simplebar/src/simplebar.css';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

// Static variables in which value the toggling functionality will be triggered
const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

// ======================= Collapse Icon View Section ======================= //

/**
 * @description - The interface for the collapse icon
 */
type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

/**
 * @description - The view for the collapse icon button
 * @param onToggleCollapse
 * @param collapseClick
 * @constructor
 */
function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

// =========================== Side Bar Item View =========================== //

/**
 * @description - The sidebar item type
 */
export type SidebarItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
  }[];
};

/**
 * @description - The interface fot the Nav Item View
 */
interface NavItemProps {
  item: SidebarItemProps;
  isShown?: boolean | undefined;
}

/**
 * @description - The nav item view
 * @param item
 * @param isShow
 * @constructor
 */
function NavItem({ item, isShown }: NavItemProps) {
  // Getting current theme
  const theme = useTheme();
  // Getting current path
  const { pathname } = useLocation();
  // Getting current nav item properties in order to render and display
  const { title, path, icon, info, children } = item;
  // Checking if the current nav item is active
  const isActiveRoot = path
    ? !!matchPath({ path, end: false }, pathname)
    : false;
  // Local state to trigger nav item open and close if the item contains
  // sub-items
  const [open, setOpen] = useState(isActiveRoot);
  // The method is handling the nav bar's item open and close toggle
  const handleOpen = () => {
    setOpen(!open);
  };
  // The variable is for styling the active tab
  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    '&:before': { display: 'block' }
  };
  // The variable is for styling the active sub-tab
  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShown && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShown && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = path
                  ? !!matchPath({ path, end: false }, pathname)
                  : false;

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) =>
                            theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main'
                          })
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShown && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

// ========================== Side Bar View Section ========================= //

/**
 * @description - The property of the side bar
 */
interface SidebarProps extends BoxProps {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  navConfig: {
    subheader: string;
    items: SidebarItemProps[];
  }[];
}

/**
 * @description - The dashboard layout's side bar view
 * @param config
 * @constructor
 */
export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  navConfig,
  ...other
}: SidebarProps) {
  // Current path variable
  const { pathname } = useLocation();
  // Current user
  const { user } = useAuth();
  // Collapse drawer variables, states and methods
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave
  } = useCollapseDrawer();

  /**
   * @description - The listener to track the path in order to toggle the
   * side bar
   */
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  /**
   * @description - The content of the side bar
   */
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      {/* ================ Avatar & Collapse Button Section ================ */}
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <LogoBisflowGreen />
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse
                onToggleCollapse={onToggleCollapse}
                collapseClick={collapseClick}
              />
            )}
          </MHidden>
        </Stack>

        {isCollapse ? (
          <MyAvatar user={user} sx={{ mx: 'auto', mb: 2 }} />
        ) : (
          <Link
            underline="none"
            component={RouterLink}
            to={'PATH_DASHBOARD.user.account'}
          >
            <AccountStyle>
              <MyAvatar user={user} />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {user?.displayName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {user?.position}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        )}
      </Stack>

      {/* ===================== Sidebar Content Section ==================== */}
      <Box {...other}>
        {navConfig.map((list) => {
          const { subheader, items } = list;
          return (
            <List key={subheader} disablePadding>
              {!isCollapse && (
                <ListSubheaderStyle>{subheader}</ListSubheaderStyle>
              )}
              {items.map((item: SidebarItemProps) => (
                <NavItem key={item.title} item={item} isShown={!isCollapse} />
              ))}
            </List>
          );
        })}
      </Box>

      {/* ========================== Help Section ========================== */}
      <Box sx={{ flexGrow: 1 }} />
      {!isCollapse && (
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}
        >
          <DocIllustration sx={{ width: 1 }} />

          <div>
            <Typography gutterBottom variant="subtitle1">
              Hi, {user?.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Need help?
              <br /> Please check our docs
            </Typography>
          </div>
          <Button href={'PATH_DOCS'} target="_blank" variant="contained">
            Documentation
          </Button>
        </Stack>
      )}
    </Scrollbar>
  );

  // The view
  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      {/* On Small Size Screen */}
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      {/* On Large Size Screen */}
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) =>
                  alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
