/**
 * @description - The Main Layout footer
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Material UI's
import { Box, Link, Container, Typography } from '@mui/material';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Logo og the bisflow
import { LogoBisflowRed } from 'components';
// Paths
import { ROOTS_HOME } from 'routes';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

export default function MainLayoutFooter() {
  return (
    <Box
      sx={{
        py: 1,
        textAlign: 'center',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        bgcolor: 'background.default'
      }}
    >
      <Container maxWidth="lg">
        <LogoBisflowRed sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} />

        <Typography variant="caption" component="p">
          © All rights reserved
          <br /> made by &nbsp;
          <Link href={ROOTS_HOME}>Bisflow.io</Link>
        </Typography>
      </Container>
    </Box>
  );
}
