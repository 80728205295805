/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 * ToDo add detailed description
 * ToDo add react-router V6
 * ToDo add react-router-dom V6
 * ToDo add <PrivateRoute https://stackoverflow.com/questions/62384395/protected-route-with-react-router-v6
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Types
import { RouteItem } from '@types';
// Components
import {
  Loadable,
  // Root paths
  PATH_BISFLOW,
  PATH_AUTH,
  // Pages paths
  PATH_APPLICATIONS
} from 'routes';
// Guards
import { AuthGuard } from 'guards';
// Layouts
import { MainLayout } from 'layouts';

// ========================================================================== //
// ================================= Pages ================================== //
// ========================================================================== //

// Bisflow Pages
const Apps = Loadable(lazy(() => import('../pages/bisflow/apps')));

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const bisflowRoutes: RouteItem = {
  path: PATH_BISFLOW.root,
  element: (
    <AuthGuard path={PATH_AUTH.login}>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_BISFLOW.applications} replace /> },
    {
      path: PATH_APPLICATIONS,
      element: <Apps />
    }
  ]
};

export default bisflowRoutes;
