/**
 * @description - ToDo delete the file as soon as the content moved to server
 */

import { TypeDocumentContent } from '@types';

const content: TypeDocumentContent[] = [
  {
    id: '1',
    documentId: 'uwcd',
    index: 0,
    type: 'title',
    language: 'am',
    value: 'ՊԱՅՄԱՆԱԳԻՐ bisflow.value.0',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 1,
    type: 'title',
    language: 'am',
    value: 'ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐԻ ՄԱՏՈՒՑՄԱՆ',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 5,
    type: 'subtitle',
    language: 'am',
    value: 'Հոդված 1. ՀԻՄՆԱԿԱՆ ՀԱՍԿԱՑՈՒԹՅՈՒՆՆԵՐԸ',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 6,
    type: 'paragraph',
    language: 'am',
    value:
      '1.1. Քանի դեռ Պայմանագրով այլ բան նախատեսված չէ, ներքոթվարկյալ սահմանումները Պայմանագրում գործածվում են հետևյալ նշանակություններով.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 7,
    type: 'paragraph',
    language: 'am',
    value:
      'i. «Պայմանագիր» - սույն  ծառայությունների մատուցման պայմանագիրը՝ իր բոլոր փոփոխություններով և հավելվածներով (եթե կիրառելի է).',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 8,
    type: 'paragraph',
    language: 'am',
    value:
      '«Ծառայություններ» - Պատվիրատուի պահանջով մատուցում է ուսանողներին bisflow.value.7 լեզվով գրականության bisflow.value.8 :',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 9,
    type: 'paragraph',
    language: 'am',
    value:
      'ii. «Գին» – Պատվիրատուի կողմից Կատարողին դրամական վճար վերջինիս կողմից Ծառայությունների մատուցման համար՝ սույն Պայմանագրով նախատեսված չափով, կարգով և պայմաններով.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 10,
    type: 'paragraph',
    language: 'am',
    value:
      'iii. «Ժամկետ» նշանակում է այն ժամկետը, որի ընթացքում Կատարողը պարտավորվում է մատուցել Ծառայությունները: Ժամկետը սկսում bisflow.value.9 ին և ավարտվում է bisflow.value.10 ին.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 11,
    type: 'paragraph',
    language: 'am',
    value:
      'iv. «Ակտ» նշանակում է Կողմերի միջև ստորագրվող հանձնման-ընդունման ակտ, որով հավաստվում է Ծառայությունների մատուցումը Կատարողի կողմից Պատվիրատուին:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 12,
    type: 'paragraph',
    language: 'am',
    value:
      '1.2. Սույն Պայմանագրում եզակի թվով օգտագործված հասկացությունները ներառում են հոգնակին և հակառակը:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 13,
    type: 'paragraph',
    language: 'am',
    value:
      '1.3. Սույն Պայմանագրի հասկացությունները Կողմերի կողմից կիրառվում են այն նշանակությամբ, որը նրանց տրվում է Հայաստանի Հանրապետության օրենսդրությամբ:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 14,
    type: 'subtitle',
    language: 'am',
    value: 'Հոդված 2. ՊԱՅՄԱՆԱԳՐԻ ԱՌԱՐԿԱՆ',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 15,
    type: 'paragraph',
    language: 'am',
    value:
      '2.1. Սույն Պայմանագրի համաձայն՝ Կատարողը պարտավորվում է մատուցել Ծառայություններ:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 16,
    type: 'paragraph',
    language: 'am',
    value:
      '2.2. Պատվիրատուն պարտավորվում է Ծառայությունների դիմաց վճարել Կատարողին՝ համաձայն Պայմանագրի պայմանների:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 17,
    type: 'subtitle',
    language: 'am',
    value: 'Հոդված 3. ԳԻՆԸ ԵՎ ՎՃԱՐՄԱՆ ԿԱՐԳԸ',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 18,
    type: 'paragraph',
    language: 'am',
    value:
      '3.1. Պատվիրատուի կողմից վճարման ենթակա փաստացի մատուցված Ծառայության Գինը կազմում է bisflow.value.11 ՀՀ դրամ, ներառյալ հարկերը և պարտադիր վճարները:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 19,
    type: 'paragraph',
    language: 'am',
    value:
      '3.2. Գինը ենթակա է վճարման Կողմերի կողմից Ակտի ստորագրման պահից 5 (հինգ) բանկային օրվա ընթացքում:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 20,
    type: 'paragraph',
    language: 'am',
    value:
      '3.3. Մատուցած Ծառայությունների որակի կամ ժամկետի վերաբերալ Պատվիրատուի կողմից առարկությունների առկայության դեպքում, Կողմերը համաձայնեցնում են Գնի համապատասխան նվազեցումը: Այդ պարագայում վերջնական վճարումը կատարվում է հաշվի առնելով Գնի նվազեցումը և Կողմերը համապատասխանաբար փոփոխում են սույն Պայմանագիրը:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 21,
    type: 'paragraph',
    language: 'am',
    value:
      '3.4. Եթե սույն Պայմանագրով այլ բան ուղղակիորեն նախատեսված չէ, Կողմերից յուրաքանչյուրը ինքնուրույն կատարում է իր բոլոր ծախսերը կապված իր պարտականությունների կատարման հետ: Գնի վճարման ժամանակ Պատվիրատուն կրում է բոլոր բանկային միջնորդավճարների վճարման ծախսերը և վճարում է այն բանկերի կողմից համապատասխան հավաքագրումները, որտեղ բացված են Պատվիրատուի և Կատարողի հաշվեհամարները, չներառելով Պատվիրատուի բանկի կողմից տվյալ գումարի օգտագործման հետ կապված բանկային վճարները',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 22,
    type: 'paragraph',
    language: 'am',
    value:
      '3.5. Սույն Պայմանագրի շրջանակներում Պատվիրատուի կողմից Կատարողին բոլոր վճարումները կատարվում են Կատարողի՝ սույն Պայմանագրում նշված հաշվին բանկային փոխանցման միջոցով:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 23,
    type: 'subtitle',
    language: 'am',
    value: 'Հոդված 4. ԿՈՂՄԵՐԻ ԻՐԱՎՈՒՆՔՆԵՐԸ և ՊԱՐՏԱԿԱՆՈՒԹՅՈՒՆՆԵՐԸ',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 24,
    type: 'paragraph',
    language: 'am',
    value: '4.1. Պատվիրատուն իրավունք ունի՝',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 25,
    type: 'paragraph',
    language: 'am',
    value:
      '4.1.1. Ցանկացած ժամանակ ստուգել մատուցվող Ծառայության որակը և ներկայացնել Կատարողին իր դիտողությունները՝  Ծառայության ընթացքի և որակի վերաբերյալ:  Կատարողը պարտավորվում է  Ծառայության մատուցումը շարունակել հաշվի առնելով Պատվիրատուի  դիտողությունները.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 26,
    type: 'paragraph',
    language: 'am',
    value:
      '4.1.2. Մինչև Ծառայության ավարտը` ցանկացած ժամանակ հրաժարվել Պայմանագիրը կատարելուց` Կատարողին վճարելով Կատարողի փաստացի կատարած ծախսերը:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 27,
    type: 'paragraph',
    language: 'am',
    value: '4.2. Պատվիրատուն պարտավոր է՝',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 28,
    type: 'paragraph',
    language: 'am',
    value:
      '4.2.1. Վճարել պատշաճ մատուցված Ծառայության դիմաց սույն Պայմանագրով սահմանված կարգով և չափով:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 29,
    type: 'paragraph',
    language: 'am',
    value: '4.3. Կատարողը պարտավոր է՝',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 30,
    type: 'paragraph',
    language: 'am',
    value: '4.3.1. Ծառայությունը մատուցել պատշաճ որակով, ամբողջ ծավալով.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 31,
    type: 'paragraph',
    language: 'am',
    value:
      '4.3.2. Ծառայությունը մատուցել սույն Պայմանագրով սահմանված Ժամկետում.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 32,
    type: 'paragraph',
    language: 'am',
    value:
      '4.3.3. Պատվիրատուի պահանջով ողջամիտ ժամկետում անհատույց վերացնել հայտնաբերված բոլոր թերությունները:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 33,
    type: 'paragraph',
    language: 'am',
    value: '4.4. Կատարողն  իրավունք ունի՝',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 34,
    type: 'paragraph',
    language: 'am',
    value:
      '4.4.1. Պահանջել Պատվիրատուից տրամադրել Ծառայությունների մատուցման համար անհրաժեշտ տեղեկատվությունները:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 35,
    type: 'subtitle',
    language: 'am',
    value: 'Հոդված 5. ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 36,
    type: 'paragraph',
    language: 'am',
    value:
      '5.1. Սույն Պայմանագրով սահմանված իրենց պարտավորությունների չկատարման կամ ոչ պատշաճ կատարման համար Կողմերը պատասխանատվություն են կրում Հայաստանի Հանրապետության օրենսդրությամբ սահմանված կարգով:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 37,
    type: 'subtitle',
    language: 'am',
    value: 'Հոդված 6. ՎԵՃԵՐԻ ԼՈՒԾՄԱՆ ԿԱՐԳԸ',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 38,
    type: 'paragraph',
    language: 'am',
    value:
      '6.1. Պայմանագրի կապակցությամբ ծագած վեճերը լուծվում են բանակցությունների միջոցով, որի համար Կողմերին տրվում է 30 (երեսուն) օրացույցային օր։',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 39,
    type: 'paragraph',
    language: 'am',
    value:
      '6.2. Վերևում նշված ժամկետում համաձայնություն ձեռք չբերելու դեպքում վեճերի լուծումը հանձնվում է  ՀՀ իրավասու դատարանի քննությանը:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 40,
    type: 'subtitle',
    language: 'am',
    value: 'Հոդված 7. ԵԶՐԱՓԱԿԻՉ ԴՐՈՒՅԹՆԵՐ',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 41,
    type: 'paragraph',
    language: 'am',
    value:
      '7.1. Պայմանագրով նախատեսված պարտավորություններն ամբողջությամբ կամ մասնակիորեն չկատարելու համար Կողմերն ազատվում են պատասխանատվությունից, եթե դա եղել է անհաղթահարելի ուժի ազդեցության արդյունք, որը ծագել է սույն Պայմանագիրը կնքելուց հետո, և որը Կողմերը չէին կարող կանխատեսել կամ կանխարգելել։ Այդպիսի իրավիճակներ են երկրաշարժը, ջրհեղեղը, հրդեհը, պատերազմը, ռազմական և արտակարգ դրության հայտարարումը, քաղաքական հուզումները, գործադուլները, հաղորդակցության միջոցների աշխատանքի դադարեցումը, պետական մարմինների ակտերը և այլն, որոնք անհնարին են դարձնում սույն Պայմանագրով սահմանված պարտավորությունների կատարումը: Եթե անհաղթահարելի ուժի ազդեցությունը շարունակվում է 5 (հինգ) օրացույցային օրից ավելի, ապա Կողմերից յուրաքանչյուրը իրավունք ունի լուծել Պայմանագիրը՝ այդ մասին նախապես տեղյակ պահելով մյուս Կողմին:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 42,
    type: 'paragraph',
    language: 'am',
    value:
      '7.2. Բոլոր խնդիրները՝ կապված Կողմերի հարաբերությունների հետ, որոնք կարգավորված չեն սույն Պայմանագրով և ծագում են սույն Պայմանագրից պետք է կարգավորվեն Հայաստանի Հանրապետության օրենսդրությամբ:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 43,
    type: 'paragraph',
    language: 'am',
    value:
      '7.3. Սույն Պայմանագիրն ուժի մեջ է մտնում երկու Կողմերի ստորագրման պահից և գործում է մինչև Կողմերի կողմից իրենց պարտականությունների կատարման ավարտը:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 44,
    type: 'paragraph',
    language: 'am',
    value:
      '7.4. Սույն Պայմանագրում կատարված փոփոխությունները և լրացումները օրինական են միայն այն դեպքում, եթե դրանք կատարված են գրավոր և ստորագրված Կողմերի կողմից։',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 45,
    type: 'paragraph',
    language: 'am',
    value:
      '7.5. Սույն Պայմանագիրը կնքված է հայերեն և անգլերեն լեզուներով, 2 (երկու) օրինակից, որոնք ունեն հավասարազոր իրավաբանական ուժ։ Յուրաքանչյուր կողմին տրվում է մեկական օրինակ։ Հայերեն և անգլերեն  տարբերակների միջև հակասությունների դեպքում, հայերեն տարբերակը ունի գերիշխող ուժ:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 46,
    type: 'paragraph',
    language: 'am',
    value:
      '7.6. Սույն Պայմանագրին կցված հավելվածները և փոփոխությունները հանդիսանում են Պայմանագրի անբաժանելի մասը:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 47,
    type: 'paragraph',
    language: 'am',
    value:
      '7.7. Սույն Պայմանագրի կնքման փաստը, Պայմանագրի դրույթները և սույն Պայմանագրի հետ կապված Կողմերին հայտնի դարձած տեղեկատվությունները համարվում են գաղտնի և պահպանվում են համապատասխան կարգով:',
    created: null,
    updated: null
  },

  // English version of the template
  {
    id: '1',
    documentId: 'uwcd',
    index: 0,
    type: 'title',
    language: 'en',
    value: 'AGREEMENT bisflow.value.0',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 1,
    type: 'title',
    language: 'en',
    value: 'ON RENDERING OF SERVICES',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 5,
    type: 'subtitle',
    language: 'en',
    value: 'Article 1. MAIN DEFINITIONS',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 6,
    type: 'paragraph',
    language: 'en',
    value:
      '1.1. Unless otherwise stipulated in the Agreement, the definitions used herein in the text of the Agreement have the following meanings:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 7,
    type: 'paragraph',
    language: 'en',
    value:
      'i. “Agreement” – the present agreement on rendering services with all of its amendments and appendices (if applicable);',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 8,
    type: 'paragraph',
    language: 'en',
    value:
      '“Services” – upon the Client’s request provides the services of bisflow.value.7 bisflow.value.8 online School-Supported, Self-Taught (SSST) for students;',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 9,
    type: 'paragraph',
    language: 'en',
    value:
      'ii. “Price” – a monetary price payable by the Client to the Provider for rendering the Services to the extent, order and on conditions, stipulated by this Agreement;',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 10,
    type: 'paragraph',
    language: 'en',
    value:
      'iii. “Term” means the term when the Provider shall provide the Services. The Term starts on bisflow.value.9 and ends on bisflow.value.10 ;',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 11,
    type: 'paragraph',
    language: 'en',
    value:
      'iv. “Act” – a handover act that confirms the handing over of Services from the Provider to the Client.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 12,
    type: 'paragraph',
    language: 'en',
    value:
      '1.2. In this Agreement, definitions denoting the singular include the plural and vice versa.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 13,
    type: 'paragraph',
    language: 'en',
    value:
      '1.3. The definitions in this Agreement are used by the Parties in the meaning, given thereto by the legislation of the Republic of Armenia.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 14,
    type: 'subtitle',
    language: 'en',
    value: 'Article 2. THE SUBJECT OF THE AGREEMENT',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 15,
    type: 'paragraph',
    language: 'en',
    value:
      '2.1. In accordance with this Agreement the Provider undertakes to render the Services.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 16,
    type: 'paragraph',
    language: 'en',
    value:
      '2.2. The Client undertakes to pay the Provider for the Services in accordance with conditions of this Agreement.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 17,
    type: 'subtitle',
    language: 'en',
    value: 'Article 3. PRICE AND ORDER OF PAYMENT',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 18,
    type: 'paragraph',
    language: 'en',
    value:
      '3.1. The Price of Services actually provided by the Provider and to be paid by the Client is bisflow.value.11 AMD, including taxes and mandatory payments.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 19,
    type: 'paragraph',
    language: 'en',
    value:
      '3.2. The Price should be paid to the Provider within 5 (five) banking days after the Parties sign the Act.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 20,
    type: 'paragraph',
    language: 'en',
    value:
      '3.3. In case of objection regarding the quality or terms of Services rendered, the Parties agree on the respective decrease of the Price. In that event, the final payment is made taking into account the Price decrease and the Parties respectively amend this Agreement.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 21,
    type: 'paragraph',
    language: 'en',
    value:
      "3.4. Unless otherwise directly stipulated by this Agreement, either Party solely bears all its costs and expenses in relation to the performance of its obligations. By paying the Price the Client shall bear all the expenses of bank commission and analogous fees, levied by the banks, where the bank accounts of the Client and the Provider are, excluding Provider's bank charges connected with the amount usage.",
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 22,
    type: 'paragraph',
    language: 'en',
    value:
      '3.5. All the payments by the Client to the Provider under this Agreement shall be made via bank transfer to the Provider’s account specified in this Agreement.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 23,
    type: 'subtitle',
    language: 'en',
    value: 'Article 4. RIGHTS AND OBLIGATIONS OF THE PARTIES',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 24,
    type: 'paragraph',
    language: 'en',
    value: '4.1. The Client is entitled to:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 25,
    type: 'paragraph',
    language: 'en',
    value:
      '4.1.1. At any time check the quality of Services being rendered and provide the Provider with remarks regarding the quality and procedure of Services rendering. The Provider undertakes to carry on the Services rendering taking into account the Client’s remarks;',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 26,
    type: 'paragraph',
    language: 'en',
    value:
      '4.1.2. Until the completion of Services at any time to refuse to perform this Agreement by paying to the Provider the actual expenses born by the Provider.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 27,
    type: 'paragraph',
    language: 'en',
    value: '4.2. The Client shall:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 28,
    type: 'paragraph',
    language: 'en',
    value:
      '4.2.1. Pay for the properly rendered Services in accordance with the procedure and amount as set forth herein.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 29,
    type: 'paragraph',
    language: 'en',
    value: '4.3. The Provider shall:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 30,
    type: 'paragraph',
    language: 'en',
    value: '4.3.1. Render the Services properly and fully;',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 31,
    type: 'paragraph',
    language: 'en',
    value: '4.3.2. Render the Services within the Term set forth herein;',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 32,
    type: 'paragraph',
    language: 'en',
    value:
      '4.3.3. Upon the Client’s request to eliminate in reasonable time and free of charge all identified defects.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 33,
    type: 'paragraph',
    language: 'en',
    value: '4.4. The Provider is entitled to:',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 34,
    type: 'paragraph',
    language: 'en',
    value:
      '4.4.1. Request the Client to provide all information necessary to render the Services.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 35,
    type: 'subtitle',
    language: 'en',
    value: 'Article 5. LIABILITY',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 36,
    type: 'paragraph',
    language: 'en',
    value:
      '5.1. The Parties shall be liable for full or partial non-performance of their obligations stated in the present Agreement in compliance with the legislation of the Republic of Armenia',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 37,
    type: 'subtitle',
    language: 'en',
    value: 'Article 6. RESOLUTION OF DISPUTES',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 38,
    type: 'paragraph',
    language: 'en',
    value:
      '6.1. Disputes arising in relation to the Agreement shall be resolved through negotiations, for which the Parties shall be provided with 30 (thirty) calendar days.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 39,
    type: 'paragraph',
    language: 'en',
    value:
      '6.2. In case of failure to reach an agreement within the time limit specified above, the dispute shall be referred to the competent court of the Republic of Armenia.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 40,
    type: 'subtitle',
    language: 'en',
    value: 'Article 7. FINAL PROVISIONS',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 41,
    type: 'paragraph',
    language: 'en',
    value:
      '7.1. The Parties shall be exempt from responsibility for the non-performance of obligations under this Agreement in whole or in part as a consequence of impact of anirresistible power (force majeure) which has arisen after the conclusion of this Agreement and which the Parties could not have foreseen or prevented. Such situations are earthquake, flood, fire, war, declaration of martial law and state of emergency, political disturbances, strikes, failure of communication systems, acts of state bodies, etc. which render the performance of obligations defined by this Agreement impossible. If the impact of force majeure persists for more than 5 (five) calendar days, each of the Parties shall have the right to terminate the Agreement upon a written prior notice to the other Party.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 42,
    type: 'paragraph',
    language: 'en',
    value:
      '7.2. All the issues in connection with the relations of the Parties not regulated by this Agreement and arising out of this Agreement shall be governed by the legislation of the Republic of Armenia.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 43,
    type: 'paragraph',
    language: 'en',
    value:
      '7.3. This Agreement shall enter into force upon its signing by the two Parties and shall be effective until full completion by the Parties of their obligations under the Agreement.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 44,
    type: 'paragraph',
    language: 'en',
    value:
      '7.4. Amendments and supplements made to this Agreement shall be lawful only when made in writing and signed by the Parties.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 45,
    type: 'paragraph',
    language: 'en',
    value:
      '7.5. This Agreement is concluded in Armenian and English, in 2 (two) copies having equal legal effect. Each Party shall be provided with one copy. In case of discrepancies between the Armenian and English texts, the text in Armenian shall prevail.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 46,
    type: 'paragraph',
    language: 'en',
    value:
      '7.6. Annexes and amendments to this Agreement shall be the inseparable part of the Agreement.',
    created: null,
    updated: null
  },
  {
    id: '1',
    documentId: 'uwcd',
    index: 47,
    type: 'paragraph',
    language: 'en',
    value:
      '7.7. The conclusion of this Agreement, provisions of this Agreement, as well as all information that became known to the Parties in relation to this Agreement shall be considered confidential and treated as such.',
    created: null,
    updated: null
  }
];

export default content;

// {/* Paragraph 1 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '(Ա) ԴԻԼԻՋԱՆ ԻՆՏԵՐՆԵՅՇՆԼ ՍՔՈՒԼ ՕՖ ԱՐՄԵՆԻԱ Հիմնադրամը ոչ առևտրային կազմակերպություն, հիմնադրված և գործող համաձայն Հայաստանի Հանրապետության օրենսդրության, գտնվելու վայր՝ Գետափնյա 7, Դիլիջան, 3903, Տավուշ, Հայաստան, ի դեմս տնօրեն Գաբրիել Էրնեստո Աբադ Ֆերնանդեսի, ով գործում է կանոնադրության հիման վրա (այսուհետ՝ «Պատվիրատու» կամ «Հիմնադրամ»),մի կողմից, և'
//                     }
//                   </Typography>
//                   {/* Paragraph 2 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       "(Բ) ${'Տիգրան Բաբայանի'}, (${'Հայաստան'}ի քաղաքացի), ծնված՝ ${'27.02.1983'}, անձնագիր՝ ${'BA 3564861'}, տրված՝ ${'02.04.2021'} թ, ${'001'}-ի կողմից (այսուհետ՝ «Կատարող»), մյուս կողմից,"
//                     }
//                   </Typography>
//                   {/* Paragraph 3 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       'այսուհետ առանձին` «Կողմ», իսկ միասին՝ «Կողմեր», կնքեցին սույն պայմանագիրը հետևյալի մասին.'
//                     }
//                   </Typography>
//
//                   {/* Paragraph 4 */}
//                   <Typography paragraph variant="subtitle1">
//                     {'Հոդված 1. ՀԻՄՆԱԿԱՆ ՀԱՍԿԱՑՈՒԹՅՈՒՆՆԵՐԸ'}
//                   </Typography>
//                   {/* Paragraph 5 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '1. Քանի դեռ Պայմանագրով այլ բան նախատեսված չէ, ներքոթվարկյալ սահմանումները Պայմանագրում գործածվում են հետևյալ նշանակություններով.'
//                     }
//                   </Typography>
//                   {/* Paragraph 6 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       'i. «Պայմանագիր» - սույն ծառայությունների մատուցման պայմանագիրը՝ իր բոլոր փոփոխություններով և հավելվածներով (եթե կիրառելի է).'
//                     }
//                   </Typography>
//                   {/* Paragraph 7 */}
//                   <Typography paragraph variant="body2">
//                     {`«Ծառայություններ» - Պատվիրատուի պահանջով մատուցում է ուսանողներին ${'ֆրանսերեն'} լեզվով գրականության ${'հեռահար ուսուցում'}․`}
//                   </Typography>
//                   {/* Paragraph 8 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       'ii. «Գին» – Պատվիրատուի կողմից Կատարողին դրամական վճար վերջինիս կողմից Ծառայությունների մատուցման համար՝ սույն Պայմանագրով նախատեսված չափով, կարգով և պայմաններով.'
//                     }
//                   </Typography>
//                   {/* Paragraph 9 */}
//                   <Typography paragraph variant="body2">
//                     {`iii. «Ժամկետ» նշանակում է այն ժամկետը, որի ընթացքում Կատարողը պարտավորվում է մատուցել Ծառայությունները: Ժամկետը սկսում ${'2021թ սեպտեմբերի 13'} ին և ավարտվում է ${'2022թ. մայիսի 31'} ին.`}
//                   </Typography>
//                   {/* Paragraph 10 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       'iv. «Ակտ» նշանակում է Կողմերի միջև ստորագրվող հանձնման-ընդունման ակտ, որով հավաստվում է Ծառայությունների մատուցումը Կատարողի կողմից Պատվիրատուին:'
//                     }
//                   </Typography>
//                   {/* Paragraph 11 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '2. Սույն Պայմանագրում եզակի թվով օգտագործված հասկացությունները ներառում են հոգնակին և հակառակը:'
//                     }
//                   </Typography>
//                   {/* Paragraph 12 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '3. Սույն Պայմանագրի հասկացությունները Կողմերի կողմից կիրառվում են այն նշանակությամբ, որը նրանց տրվում է Հայաստանի Հանրապետության օրենսդրությամբ:'
//                     }
//                   </Typography>
//
//                   {/* Paragraph 13 */}
//                   <Typography paragraph variant="subtitle1">
//                     {'Հոդված 2. ՊԱՅՄԱՆԱԳՐԻ ԱՌԱՐԿԱՆ'}
//                   </Typography>
//                   {/* Paragraph 14 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '1. Սույն Պայմանագրի համաձայն՝ Կատարողը պարտավորվում է մատուցել Ծառայություններ:'
//                     }
//                   </Typography>
//                   {/* Paragraph 15 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '2. Պատվիրատուն պարտավորվում է Ծառայությունների դիմաց վճարել Կատարողին՝ համաձայն Պայմանագրի պայմանների:'
//                     }
//                   </Typography>
//
//                   {/* Paragraph 16 */}
//                   <Typography paragraph variant="subtitle1">
//                     {'Հոդված 3. ԳԻՆԸ ԵՎ ՎՃԱՐՄԱՆ ԿԱՐԳԸ'}
//                   </Typography>
//                   {/* Paragraph 17 */}
//                   <Typography paragraph variant="body2">
//                     {`1. Պատվիրատուի կողմից վճարման ենթակա փաստացի մատուցված Ծառայության Գինը կազմում է ${'97,000 (իննսունյոթ հազար)'} ՀՀ դրամ, ներառյալ հարկերը և պարտադիր վճարները:`}
//                   </Typography>
//                   {/* Paragraph 18 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '2. Գինը ենթակա է վճարման Կողմերի կողմից Ակտի ստորագրման պահից 5 (հինգ) բանկային օրվա ընթացքում:'
//                     }
//                   </Typography>
//                   {/* Paragraph 19 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '3. Մատուցած Ծառայությունների որակի կամ ժամկետի վերաբերալ Պատվիրատուի կողմից առարկությունների առկայության դեպքում, Կողմերը համաձայնեցնում են Գնի համապատասխան նվազեցումը: Այդ պարագայում վերջնական վճարումը կատարվում է հաշվի առնելով Գնի նվազեցումը և Կողմերը համապատասխանաբար փոփոխում են սույն Պայմանագիրը:'
//                     }
//                   </Typography>
//                   {/* Paragraph 20 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '4. Եթե սույն Պայմանագրով այլ բան ուղղակիորեն նախատեսված չէ, Կողմերից յուրաքանչյուրը ինքնուրույն կատարում է իր բոլոր ծախսերը կապված իր պարտականությունների կատարման հետ: Գնի վճարման ժամանակ Պատվիրատուն կրում է բոլոր բանկային միջնորդավճարների վճարման ծախսերը և վճարում է այն բանկերի կողմից համապատասխան հավաքագրումները, որտեղ բացված են Պատվիրատուի և Կատարողի հաշվեհամարները, չներառելով Պատվիրատուի բանկի կողմից տվյալ գումարի օգտագործման հետ կապված բանկային վճարները'
//                     }
//                   </Typography>
//                   {/* Paragraph 21 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '5. Սույն Պայմանագրի շրջանակներում Պատվիրատուի կողմից Կատարողին բոլոր վճարումները կատարվում են Կատարողի՝ սույն Պայմանագրում նշված հաշվին բանկային փոխանցման միջոցով:'
//                     }
//                   </Typography>
//
//                   {/* Paragraph 22 */}
//                   <Typography paragraph variant="subtitle1">
//                     {'Հոդված 4. ԿՈՂՄԵՐԻ ԻՐԱՎՈՒՆՔՆԵՐԸ և ՊԱՐՏԱԿԱՆՈՒԹՅՈՒՆՆԵՐԸ'}
//                   </Typography>
//                   {/* Paragraph 23 */}
//                   <Typography paragraph variant="body2">
//                     {'4.1. Պատվիրատուն իրավունք ունի՝'}
//                   </Typography>
//                   {/* Paragraph 24 */}
//                   <Typography paragraph variant="body2">
//                     {
//                       '   4.1.1 Ցանկացած ժամանակ ստուգել մատուցվող Ծառայության որակը և ներկայացնել Կատարողին իր դիտողությունները՝  Ծառայության ընթացքի և որակի վերաբերյալ:  Կատարողը պարտավորվում է  Ծառայության մատուցումը շարունակել հաշվի առնելով Պատվիրատուի  դիտողությունները.'
//                     }
//                   </Typography>
