/**
 * @description - Google Analytics utils to setup and register user action on
 * Google analytics
 */

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Configuration
import { googleAnalyticsConfig } from 'configs';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

// Setting up the Google analytics
const setup = (
  targetId: string,
  config?: Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams
) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  if (!window.gtag) {
    return;
  }
  window.gtag('config', targetId, config);
};

// Setting up the user event for Google analytics
const setupEvent = (
  targetId: string,
  config?: Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams
) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  if (!window.gtag) {
    return;
  }
  window.gtag('event', targetId, config);
};

// Tracking the user
const track = {
  pageview: (
    props?: Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams
  ) => {
    setup(googleAnalyticsConfig || '', props);
  },
  event: (
    type: string,
    props?: Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams
  ) => {
    setupEvent(type, props);
  }
};

export default track;
