/**
 * @description - The Main Layout view
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React routing
import { Outlet } from 'react-router-dom';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Components
import { MainLayoutFooter } from 'components';
// Styles
import { MainLayoutStyle } from './mainLayoutStyles';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The main layout view
 * @constructor
 */
export default function MainLayout() {
  return (
    <>
      <MainLayoutStyle>
        <Outlet />
      </MainLayoutStyle>
      <MainLayoutFooter />
    </>
  );
}
