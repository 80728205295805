/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 * ToDo add detailed description
 * ToDo add react-router V6
 * ToDo add react-router-dom V6
 * ToDo add <PrivateRoute https://stackoverflow.com/questions/62384395/protected-route-with-react-router-v6
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Types
import { RouteItem } from '@types';
// Components
import {
  Loadable,
  // Root paths
  PATH_ADMIN,
  PATH_AUTH,
  // Pages paths
  PATH_DASHBOARD,
  PATH_SECTION_PROCESSES,
  PATH_ACCOUNT,
  PATH_SECTION_PERSONNEL,
  PATH_SECTION_REPORTS,
  PATH_SECTION_DOCUMENTS,
  PATH_SECTION_FINANCE,
  PATH_SECTION_INVENTORY,
  PATH_SECTION_VENDORS,
  PATH_SECTION_REQUESTS
} from 'routes';
// Guards
import { AuthGuard } from 'guards';
// Layouts
import { DashboardLayout } from 'layouts';

// ========================================================================== //
// ================================= Pages ================================== //
// ========================================================================== //

// Common pages
const Maintenance = Loadable(lazy(() => import('../pages/maintenance')));

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const adminRoutes: RouteItem = {
  path: PATH_ADMIN.root,
  element: (
    <AuthGuard path={PATH_AUTH.login}>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_ADMIN.dashboard} replace /> },
    // General section
    // Account
    {
      path: PATH_ACCOUNT,
      element: <Maintenance />
    },
    // Dashboard
    {
      path: PATH_DASHBOARD,
      element: <Maintenance />
    },
    // Process section - REDIRECT -
    {
      path: PATH_SECTION_PROCESSES,
      element: <Navigate to={PATH_ADMIN.processes.forms} replace />
    },
    // Process section - FORMS -
    {
      path: PATH_ADMIN.processes.forms_section,
      element: <Maintenance />
    },
    // Process section - WORKFLOWS -
    {
      path: PATH_ADMIN.processes.flows_section,
      element: <Maintenance />
    },

    // Personnel section - REDIRECT -
    {
      path: PATH_SECTION_PERSONNEL,
      element: <Navigate to={PATH_ADMIN.personnel.team} replace />
    },
    // Personnel section - TEAM -
    {
      path: PATH_ADMIN.personnel.team_section,
      element: <Maintenance />
    },
    // Personnel section - DEPARTMENTS -
    {
      path: PATH_ADMIN.personnel.departments_section,
      element: <Maintenance />
    },
    // Personnel section - DEPARTMENTS -
    {
      path: PATH_ADMIN.personnel.groups_section,
      element: <Maintenance />
    },

    // Finances section - REDIRECT -
    {
      path: PATH_SECTION_FINANCE,
      element: <Navigate to={PATH_ADMIN.finance.payments} replace />
    },
    // Finances section - BUDGETS -
    {
      path: PATH_ADMIN.finance.budgets_section,
      element: <Maintenance />
    },
    // Finances section - PAYMENTS -
    {
      path: PATH_ADMIN.finance.payments_section,
      element: <Maintenance />
    },
    // Finances section - INVOICES -
    {
      path: PATH_ADMIN.finance.invoices_sections,
      element: <Maintenance />
    },

    // Inventory section - REDIRECT -
    {
      path: PATH_SECTION_INVENTORY,
      element: <Navigate to={PATH_ADMIN.inventory.goods} replace />
    },
    // Inventory section - STOCKS -
    {
      path: PATH_ADMIN.inventory.stocks_section,
      element: <Maintenance />
    },
    // Inventory section - GOODS -
    {
      path: PATH_ADMIN.inventory.goods_section,
      element: <Maintenance />
    },

    // Providers section - REDIRECT -
    {
      path: PATH_SECTION_VENDORS,
      element: <Navigate to={PATH_ADMIN.vendors.manage} replace />
    },
    // Providers section - STOCKS -
    {
      path: PATH_ADMIN.vendors.manage_section,
      element: <Maintenance />
    },

    // Requests section - REDIRECT -
    {
      path: PATH_SECTION_REQUESTS,
      element: <Navigate to={PATH_ADMIN.requests.goods_requests} replace />
    },
    // Requests section - STOCKS -
    {
      path: PATH_ADMIN.requests.goods_requests_section,
      element: <Maintenance />
    },

    // Documents section - REDIRECT -
    {
      path: PATH_SECTION_DOCUMENTS,
      element: <Navigate to={PATH_ADMIN.documents.archiveContracts} replace />
    },
    // Documents section - BUDGETS -
    {
      path: PATH_ADMIN.documents.authorizations_section,
      element: <Maintenance />
    },
    // Documents section - PAYMENTS -
    {
      path: PATH_ADMIN.documents.processingContracts_section,
      element: <Maintenance />
    },
    // Documents section - INVOICES -
    {
      path: PATH_ADMIN.documents.archiveContracts_section,
      element: <Maintenance />
    },

    // Reports section - REDIRECT -
    {
      path: PATH_SECTION_REPORTS,
      element: <Navigate to={PATH_ADMIN.reports.financial} replace />
    },
    // Reports section - BUDGETS -
    {
      path: PATH_ADMIN.reports.procurement_section,
      element: <Maintenance />
    },
    // Reports section - PAYMENTS -
    {
      path: PATH_ADMIN.reports.contracts_section,
      element: <Maintenance />
    },
    // Reports section - INVOICES -
    {
      path: PATH_ADMIN.reports.financial_section,
      element: <Maintenance />
    }
  ]
};

export default adminRoutes;
