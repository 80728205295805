/**
 * @description - React hook method for settings provider
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

import { useContext } from 'react';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

import { ContextFirebaseAuth } from 'contexts';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Authentication context method that provides auth
 */
const useAuth = () => {
  const context = useContext(ContextFirebaseAuth);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useAuth;
