/**
 * @description - The Avatar component. Displaying user's avatar. If the image
 * exist then show the image otherwise show the first letter from name with
 * color
 */

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Core components
import { MAvatar } from 'components';
// Types
import { MAvatarProps } from 'components/@material-extend/MAvatar';
import { TypeUserProfile } from '@types';
// Utils
import createAvatar from 'utils/createAvatar';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The interface of the MyAvatar local component
 */
interface MyAvatarProps extends MAvatarProps {
  user: TypeUserProfile;
}

/**
 * @description - The local component for avatar
 * @param user
 * @param other
 * @constructor
 */
export default function MyAvatar({ user, ...other }: MyAvatarProps) {
  return (
    <MAvatar
      src={user?.photoUrl}
      alt={user?.displayName}
      color={user?.photoUrl ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </MAvatar>
  );
}
