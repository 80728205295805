/**
 * @description - The path variables
 */

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Application based full paths
 */
// Admin application path
export const PATH_ADMIN_FULL = 'https://admin.bisflow.io';
// Document management application
export const PATH_CLM_FULL = 'https://clm.bisflow.io';
// Procurement application to manage buying staff
export const PATH_PROCUREMENT_FULL = 'https://procurement.bisflow.io';
// Request management application full path
export const PATH_REQUEST_MANAGEMENT_FULL = 'https://requests.bisflow.io';
// HR management application to manage HR workflow
export const PATH_HR_FULL = 'https://hr.bisflow.io';
// Payva - Expense management application
export const PATH_PAYVA_FULL = 'https://payva.bisflow.io';

// Home path
export const ROOTS_HOME = '/';
// Admin path
export const ROOTS_APPLICATIONS = '/applications';
// Admin path
export const ROOTS_ADMIN = ROOTS_HOME; // '/admin';
// Root path for authentication pages and components - common for all platforms
export const ROOTS_AUTH = '/auth';
// Root path for procurement platform, works only for payva
export const ROOTS_PAYVA = '/payva';
// Root path for procurement platform, works only for procurement
export const ROOTS_PROCUREMENT = '/procurement';
// Root path for procurement platform, works only for procurement
export const ROOTS_DOCUMENTS = ROOTS_HOME; // '/documents';

/**
 * SUB-LINK AUTHENTICATION SUB PATHS
 */
export const PATH_LOGIN = 'login';
export const PATH_LOGIN_UNPROTECTED = 'login-password-password-unprotected';
export const PATH_REGISTER = 'register';
export const PATH_REGISTER_UNPROTECTED = 'register-unprotected';
export const PATH_RESET_PASSWORD = 'reset-password';
export const PATH_VERIFY_PASSWORD = 'verify-password';

/**
 * SUB-LINK BISFLOW SUB PATHS
 */
export const PATH_APPLICATIONS = 'apps';

/**
 * SUB-LINK SECTIONS SUB PATHS
 */
export const PATH_SECTION_PROCESSES = 'processes';
export const PATH_SECTION_PERSONNEL = 'personnel';
export const PATH_SECTION_FINANCE = 'finance';
export const PATH_SECTION_INVENTORY = 'inventory';
export const PATH_SECTION_DOCUMENTS = 'documents';
export const PATH_SECTION_REPORTS = 'reports';
export const PATH_SECTION_VENDORS = 'vendors';
export const PATH_SECTION_REQUESTS = 'requests';

/**
 * SUB-LINK COMMON SUB PATHS
 */
export const PATH_ACCOUNT = 'account';
export const PATH_DASHBOARD = 'dashboard';
export const PATH_TEAM = 'team';
export const PATH_DEPARTMENTS = 'departments';
export const PATH_GROUPS = 'groups';
export const PATH_BUDGETS = 'budgets';
export const PATH_PAYMENTS = 'payments';
export const PATH_INVOICES = 'invoices';
export const PATH_FLOWS = 'flows';
export const PATH_FORMS = 'forms';
export const PATH_STOCKS = 'stocks';
export const PATH_GOODS = 'goods';
export const PATH_MANAGE = 'manage';
export const PATH_CONTRACTS = 'contracts';
export const PATH_AUTHORIZATIONS = 'authorizations';
export const PATH_ARCHIVE = 'archive';
export const PATH_PROCUREMENT = 'procurement';
export const PATH_FINANCIAL = 'financial';

/**
 * @description - The method is concat paths and return final path as string.
 * @example - Above example returns string of final path '/app/dashboard'
 * path('/app', '/dashboard')
 * @param root - Root link
 * @param subLink - SubLink
 * @return string - Final path after concatenation
 */
function path(root: string, subLink: string) {
  // Checking if the root path is just a '/', in that case the final path should
  // be only the sub-link
  return root !== '/' ? `${root}/${subLink}` : subLink;
}

/**
 * @description - Contains authentication related all paths
 */
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, PATH_LOGIN),
  loginUnprotected: path(ROOTS_AUTH, PATH_LOGIN_UNPROTECTED),
  register: path(ROOTS_AUTH, PATH_REGISTER),
  registerUnprotected: path(ROOTS_AUTH, PATH_REGISTER_UNPROTECTED),
  resetPassword: path(ROOTS_AUTH, PATH_RESET_PASSWORD),
  verify: path(ROOTS_AUTH, PATH_VERIFY_PASSWORD)
};

/**
 * @description - Contains all paths related to bisflow
 */
export const PATH_BISFLOW = {
  root: ROOTS_HOME,
  applications: path(ROOTS_HOME, PATH_APPLICATIONS)
};

/**
 * @description - Contains administrative dashboard related paths
 */
export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  dashboard: path(ROOTS_ADMIN, PATH_DASHBOARD),
  processes: {
    root: path(ROOTS_ADMIN, PATH_SECTION_PROCESSES),
    flows: path(ROOTS_ADMIN, path(PATH_SECTION_PROCESSES, PATH_FLOWS)),
    flows_section: path(PATH_SECTION_PROCESSES, PATH_FLOWS),
    forms: path(ROOTS_ADMIN, path(PATH_SECTION_PROCESSES, PATH_FORMS)),
    forms_section: path(PATH_SECTION_PROCESSES, PATH_FORMS)
  },
  personnel: {
    root: path(ROOTS_ADMIN, PATH_SECTION_PERSONNEL),
    team: path(ROOTS_ADMIN, path(PATH_SECTION_PERSONNEL, PATH_TEAM)),
    team_section: path(PATH_SECTION_PERSONNEL, PATH_TEAM),
    team_edit: path(PATH_SECTION_PERSONNEL, PATH_TEAM),
    departments: path(
      ROOTS_ADMIN,
      path(PATH_SECTION_PERSONNEL, PATH_DEPARTMENTS)
    ),
    departments_section: path(PATH_SECTION_PERSONNEL, PATH_DEPARTMENTS),
    groups: path(ROOTS_ADMIN, path(PATH_SECTION_PERSONNEL, PATH_GROUPS)),
    groups_section: path(PATH_SECTION_PERSONNEL, PATH_GROUPS)
  },
  finance: {
    root: path(ROOTS_ADMIN, PATH_SECTION_FINANCE),
    budgets: path(ROOTS_ADMIN, path(PATH_SECTION_FINANCE, PATH_BUDGETS)),
    budgets_section: path(PATH_SECTION_FINANCE, PATH_BUDGETS),
    payments: path(ROOTS_ADMIN, path(PATH_SECTION_FINANCE, PATH_PAYMENTS)),
    payments_section: path(PATH_SECTION_FINANCE, PATH_PAYMENTS),
    invoices: path(ROOTS_ADMIN, path(PATH_SECTION_FINANCE, PATH_INVOICES)),
    invoices_sections: path(PATH_SECTION_FINANCE, PATH_INVOICES)
  },
  inventory: {
    root: path(ROOTS_ADMIN, PATH_SECTION_INVENTORY),
    stocks: path(ROOTS_ADMIN, path(PATH_SECTION_INVENTORY, PATH_STOCKS)),
    stocks_section: path(PATH_SECTION_INVENTORY, PATH_STOCKS),
    goods: path(ROOTS_ADMIN, path(PATH_SECTION_INVENTORY, PATH_GOODS)),
    goods_section: path(PATH_SECTION_INVENTORY, PATH_GOODS)
  },
  vendors: {
    root: path(ROOTS_ADMIN, PATH_SECTION_VENDORS),
    manage: path(ROOTS_ADMIN, path(PATH_SECTION_VENDORS, PATH_MANAGE)),
    manage_section: path(PATH_SECTION_VENDORS, PATH_MANAGE)
  },
  requests: {
    root: path(ROOTS_ADMIN, PATH_SECTION_REQUESTS),
    goods_requests: path(ROOTS_ADMIN, path(PATH_SECTION_REQUESTS, PATH_GOODS)),
    goods_requests_section: path(PATH_SECTION_REQUESTS, PATH_GOODS)
  },
  documents: {
    root: path(ROOTS_ADMIN, PATH_SECTION_DOCUMENTS),
    archiveContracts: path(
      ROOTS_ADMIN,
      path(PATH_SECTION_DOCUMENTS, PATH_ARCHIVE)
    ),
    archiveContracts_section: path(PATH_SECTION_DOCUMENTS, PATH_ARCHIVE),
    authorizations: path(
      ROOTS_ADMIN,
      path(PATH_SECTION_DOCUMENTS, PATH_AUTHORIZATIONS)
    ),
    authorizations_section: path(PATH_SECTION_DOCUMENTS, PATH_AUTHORIZATIONS),
    processingContracts: path(
      ROOTS_ADMIN,
      path(PATH_SECTION_DOCUMENTS, PATH_CONTRACTS)
    ),
    processingContracts_section: path(PATH_SECTION_DOCUMENTS, PATH_CONTRACTS)
  },
  reports: {
    root: path(ROOTS_ADMIN, PATH_SECTION_REPORTS),
    procurement: path(
      ROOTS_ADMIN,
      path(PATH_SECTION_REPORTS, PATH_PROCUREMENT)
    ),
    procurement_section: path(PATH_SECTION_REPORTS, PATH_PROCUREMENT),
    contracts: path(ROOTS_ADMIN, path(PATH_SECTION_REPORTS, PATH_CONTRACTS)),
    contracts_section: path(PATH_SECTION_REPORTS, PATH_CONTRACTS),
    financial: path(ROOTS_ADMIN, path(PATH_SECTION_REPORTS, PATH_FINANCIAL)),
    financial_section: path(PATH_SECTION_REPORTS, PATH_FINANCIAL)
  }
};

/**
 * @description - Contains home paths
 */
export const PATH_HOME = {
  root: ROOTS_HOME,
  account: path(ROOTS_ADMIN, '/account')
};

/**
 * @description - Contains Contract Dashboard's paths
 */
export const PATH_DOCUMENTS_PLATFORM = {
  root: ROOTS_DOCUMENTS,
  dashboard: path(ROOTS_DOCUMENTS, '/dashboard'),
  contracts: path(ROOTS_DOCUMENTS, '/contracts')
};

/**
 * @description - Contains Procurement Dashboard's paths
 */
export const PATH_PROCUREMENT_PLATFORM = {
  root: ROOTS_PROCUREMENT,
  dashboard: path(ROOTS_PROCUREMENT, '/dashboard'),
  requests: path(ROOTS_PROCUREMENT, '/requests')
};
